import React from "react";
import { Card, CardHeader } from "@material-ui/core";

import Item from "./Item";

const Comments = ({ setId, comments }) => {
  return (
    <div className="mt-5 comment-container">
      <Card variant="outlined">
        <CardHeader title="Comments" />
        <div className="comment-wrapper">
          {comments?.map((comment, index) => (
            <div key={index}>
              <Item comment={comment} onDelete={() => setId(comment?._id)} />
              {comments.length - 1 !== index && <hr className="mx-4 my-2" />}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default Comments;

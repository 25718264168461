import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";
import { toast } from "react-toastify";

import * as types from "./../types/category";
import * as service from "./../services/category";
import * as actions from "./../actions/categoryActions";
import { errorResponse, successResponse } from "./auth";

function* getCategories({ query, token }) {
  try {
    const result = yield call(service.categories, query, token);
    yield put(actions.getCategoriesSuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* addCategory(action) {
  try {
    const { category, token } = action.payload;
    const result = yield call(service.addCategory, category, token);
    yield put(actions.addCategorySuccess(result.data));
    yield put(push("/dashboard/categories"));
  } catch (error) {
    errorResponse(error);
  }
}

function* deleteCategory(action) {
  try {
    const { id, token } = action.payload;
    const result = yield call(service.deleteCategory, id, token);
    yield put(actions.deleteCategorySuccess(id));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
  }
}

function* updateCategory(action) {
  try {
    const { id, category, token } = action.payload;
    const result = yield call(service.updateCategory, id, category, token);
    yield put(actions.updateCategorySuccess(result.data));
    yield put(push("/dashboard/categories"));
  } catch (error) {
    errorResponse(error);
  }
}

function* updateCategoryPriority(action) {
  try {
    const { categories, token } = action.payload;
    yield call(service.updateCategoryPriority, categories, token);
    yield put(actions.updateCategoryPrioritySuccess());
  } catch (error) {
    errorResponse(error);
  }
}

function* hideCategory(action) {
  try {
    const { id, category, token } = action.payload;

    const result = yield call(service.hideCategory, id, category, token);
    yield put(actions.hideCategorySuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* trashCategory({ id, payload, token }) {
  try {
    const result = yield call(service.trashCategory, id, payload, token);
    yield put(actions.trashCategorySuccess(id));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
  }
}

export default function* categoryWatcher() {
  yield takeLatest(types.ADD_CATEGORY, addCategory);
  yield takeLatest(types.GET_CATEGORIES, getCategories);
  yield takeLatest(types.DELETE_CATEGORY, deleteCategory);
  yield takeLatest(types.UPDATE_CATEGORY, updateCategory);
  yield takeLatest(types.UPDATE_CATEGORY_PRIORITY, updateCategoryPriority);
  yield takeLatest(types.HIDE_CATEGORY, hideCategory);
  yield takeLatest(types.TRASH_CATEGORY, trashCategory);
}

import React from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    Col
} from "reactstrap";

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TransformIcon from '@material-ui/icons/Transform';

const Ticket = (props) => {

    return (
      <Card
        style={{
          backgroundColor: "transparent",
          flexDirection: "inherit",
          margin: "8px"
        }}
      >
        <CardHeader
          style={{
            background: props.color,
            WebkitWritingMode: "vertical-rl",
            verticalAlign: "middle",
            borderRadius: "calc(0.375rem - 1px) 0 0 calc(0.375rem - 1px)"
          }}
        >
          <div style={{ textAlign: "center" }}>
            {props.type === "earning" && (
              <AttachMoneyIcon color="inherit" />
            )}
            {props.type === "withdrawal" && <CreditCardIcon color="inherit" />}
            {props.type === "refund" && <TransformIcon color="inherit" />}
          </div>
        </CardHeader>
        <CardFooter>
          <p>
            <span className="mb-0 h3" style={{ color: "#38b7ef" }}>
              <AttachMoneyIcon />
              {props.amount}
            </span>
            Total {props.type}
          </p>
        </CardFooter>
      </Card>
    );
}

export default Ticket;
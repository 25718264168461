import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import Joi from "joi-browser";
import { connect } from "react-redux";
import * as authActions from "./../../store/actions/authActions";
import { loginSchema } from "../../utilities/Schemas/auth";
import { resetPasswordSchema } from "../../utilities/Schemas/changePassword";
import { withRouter } from "react-router-dom";

const ResetPassword = (props) => {
  const { resetPassword, auth, match } = props;
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(inputs, resetPasswordSchema, options);
    if (!error) return null;
    const errors = {};
    error.details.map((e) => {
      errors[e.path[0]] = e.message;
    });
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: resetPasswordSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  const handleInputChange = ({ currentTarget: input }) => {
    auth.error = "";
    const errorMessage = validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    setInputs({ ...inputs, [input.name]: input.value });
  };

  const handleSubmit = () => {
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    resetPassword({ password: inputs.password, resetLink: match?.params?.token });
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody>
          <div className="text-center text-muted mb-4 mt-4">
            <h2 className="text-muted">Change password</h2>
          </div>
          {auth.error && <Alert color="danger">{auth.error}</Alert>}
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  name="password"
                  type="password"
                  onChange={handleInputChange}
                />
              </InputGroup>
              {errors.password && (
                <Alert color="danger">{errors.password}</Alert>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  onChange={handleInputChange}
                />
              </InputGroup>
              {errors.confirmPassword && (
                <Alert color="danger">{errors.confirmPassword}</Alert>
              )}
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4"
                onClick={handleSubmit}
                color="primary"
                type="button"
              >
                Submit
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload) => dispatch(authActions.resetPassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));

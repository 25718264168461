import React from "react";
import { TfiDownload } from "react-icons/tfi";
import { saveFile } from "../../utilities";

const FilePreview = ({ file, withoutImage = false }) => {
  return (
    <div
      onClick={() => saveFile(file?.url, file?.name)}
      className="filePreviewContainer"
    >
      {!withoutImage && (
        <img
          className="fileImage"
          src={file?.url}
          width={300}
          height={200}
          alt="File preview"
        />
      )}
      {withoutImage && <div className="withoutImageIndicator"></div>}
      <div className="fileDetails">
        <span className="fileName">{file?.name}</span>
        <TfiDownload className="downloadIcon" />
      </div>
    </div>
  );
};

export default FilePreview;

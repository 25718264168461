import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ArticleSkeleton = () => {
  return (
    <tr>
      <td><Skeleton variant="rect" width={60} height={60} /></td>
      <td>
        <div className='d-flex flex-column'>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </td>
      <td align='center'><Skeleton variant="text" width={20} /></td>
      <td><Skeleton variant="text" /></td>
      <td><Skeleton variant="text" /></td>
      <td><Skeleton variant="text" /></td>
    </tr>
  )
}

export default ArticleSkeleton
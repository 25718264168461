import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

class ActionButton extends Component {
  render() {
    const { category, handleEdit, handleDelete, handleHide } = this.props;
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light kb-topmenu"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            style={{ textTransform: "capitalize" }}
            onClick={() => handleEdit(category._id)}
          >
            Edit
          </DropdownItem>
          <DropdownItem
            style={{ textTransform: "capitalize" }}
            onClick={() => handleDelete(category)}
          >
            Delete
          </DropdownItem>
          <DropdownItem
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              handleHide(category._id, !category.hide_Status);
            }}
          >
            {category.hide_Status ? "Show" : "Hide"}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default ActionButton;

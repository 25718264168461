import React from "react";
import { Link } from "react-router-dom";
import { FiberManualRecord } from "@material-ui/icons";
import moment from "moment";

import { getNotificationMessageAndLink } from "./notifications";

function NotificationItem({
  token,
  notification,
  setShowNotification,
  markNotification,
}) {
  const notificationData = getNotificationMessageAndLink(notification);
  const { message, link, clientReadStatus, createdAt } = notificationData ?? {};

  const handleOnClick = () => {
    if (clientReadStatus == "UNREAD") {
      markNotification(token, notification._id);
    }
    setShowNotification && setShowNotification(false);
  };

  if (!notificationData) {
    return <></>;
  }

  return link ? (
    <Link onClick={handleOnClick} to={link} className="wrapper">
      <div className="item">
        <div className="title-wrapper">
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <div className="text-xs">{moment(createdAt).fromNow()}</div>
        </div>
        {clientReadStatus == "UNREAD" && (
          <FiberManualRecord className="unread-dot" />
        )}
      </div>
    </Link>
  ) : (
    <div onClick={handleOnClick} className="item">
      <div className="title-wrapper">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <div className="text-xs">{moment(createdAt).fromNow()}</div>
      </div>
      {clientReadStatus == "UNREAD" && (
        <FiberManualRecord className="unread-dot" />
      )}
    </div>
  );
}

export default NotificationItem;

import * as types from "./../types/category";
const initialState = {
  categories: [],
  loading: false,
  error: "",
};

const categoryReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case types.ADD_UPDATE_CATEGORY_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: actions.categories,
      };
    case types.ADD_CATEGORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, actions.payload],
        loading: false,
      };
    case types.DELETE_CATEGORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.DELETE_CATEGORY_SUCCESS:
    case types.TRASH_CATEGORY_SUCCESS:
      const payload = actions.payload;
      const categories = state?.categories ?? [];
      const filteredCategory = categories.filter((e) => e._id !== payload);
      return (state = {
        ...state,
        categories: filteredCategory,
        loading: false,
      });

    case types.UPDATE_CATEGORY_PRIORITY: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UPDATE_CATEGORY_PRIORITY_SUCCESS: {
      return { ...state, loading: false };
    }
    case types.UPDATE_CATEGORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: state.categories?.map((ele) => {
          if (ele._id === actions.payload._id)
            return { ...ele, ...actions.payload };
          else return ele;
        }),
        loading: false,
      };
    }
    case types.HIDE_CATEGORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.HIDE_CATEGORY_SUCCESS: {
      let categories = [...state.categories];
      let index = categories.findIndex((e) => e._id === actions.payload._id);
      categories[index].hide_Status = actions.payload.hide_Status;
      return { ...state, categories, loading: false };
    }
    case types.RESET_CATEGORY: {
      return initialState;
    }
    default:
      return state;
  }
};

export default categoryReducers;

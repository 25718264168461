import React from "react";
import { connect } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { authSelector } from "../../store/reducers/index";

class ArticleManagerNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <h3 className="h3 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
            </h3>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.userName.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userName: authSelector(state),
});

export default connect(mapStateToProps)(ArticleManagerNavbar);

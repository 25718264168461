import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Table, Container } from "reactstrap";
import { connect } from "react-redux";

import Item from "./Item";
import Header from "../../components/Headers/DefaultHeader";
import CustomPagination from "../Pagination/pagination";
import * as userActions from "./../../store/actions/userActions";
import Loading from "../../utilities/loading";

const Feedback = (props) => {
  const { getReviews, updateReview, reviews, auth, loading } = props;
  const [pagination, setPagination] = useState({ start: 0, end: 10 });

  const { start, end } = pagination;

  useEffect(() => {
    getReviews(auth?.token);
  }, [auth?.token]);

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  const onUpdateReview = ({ orderItemId, note }) => {
    updateReview({ orderItemId, note }, auth?.token);
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Feedbacks</h3>
          </CardHeader>
          <div className="position-relative">
            <Loading
              width={70}
              height={70}
              loading={loading}
              className="kb-loading-table"
            />
            <Table
              className={`${
                loading ? "kb-overlay" : ""
              } "align-items-center table-flush"`}
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Feedback</th>
                  <th scope="col">Note</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reviews?.length === 0 ? (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Record not found
                    </td>
                  </tr>
                ) : (
                  reviews?.slice(start, end)?.map((data, index) => {
                    return (
                      <Item
                        key={index}
                        data={data}
                        onUpdateReview={onUpdateReview}
                      />
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <CustomPagination
                showPerPage={10}
                paginationHandler={paginationHandler}
                total={reviews && reviews.length}
              />
            </nav>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    reviews: state.user.reviews,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReviews: (token, query) =>
      dispatch(userActions.getReviews(token, query)),
    updateReview: (payload, token) =>
      dispatch(userActions.updateReview(payload, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

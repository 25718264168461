export const GET_VENDOR_PAYMENT = 'GET_VENDOR_PAYMENT'
export const GET_VENDOR_PAYMENT_SUCCESS = 'GET_VENDOR_PAYMENT_SUCCESS'
export const GET_VENDOR_PAYMENT_FAILURE = 'GET_VENDOR_PAYMENT_FAILURE'
export const GET_ADMIN_PAYMENT = 'GET_ADMIN_PAYMENT'
export const GET_ADMIN_PAYMENT_SUCCESS = 'GET_ADMIN_PAYMENT_SUCCESS'
export const GET_ADMIN_PAYMENT_FAILURE = 'GET_ADMIN_PAYMENT_FAILURE'


export const VENDORE_REFUND = 'VENDORE_REFUND'
export const VENDORE_REFUND_SUCCESS = 'VENDORE_REFUND_SUCCESS'
export const VENDORE_REFUND_FAILURE = 'VENDORE_REFUND_FAILURE'

export const GET_VENDORE_REFUND = 'GET_VENDORE_REFUND'
export const GET_VENDORE_REFUND_SUCCESS = 'GET_VENDORE_REFUND_SUCCESS'
export const GET_VENDORE_REFUND_FAILURE = 'GET_VENDORE_REFUND_FAILURE'

export const ADMIN_REFUND = 'ADMIN_REFUND'
export const ADMIN_REFUND_SUCCESS = 'ADMIN_REFUND_SUCCESS'
export const ADMIN_REFUND_FAILURE = 'ADMIN_REFUND_FAILURE'

export const RESET_PAYMENT = "RESET_PAYMENT";

export const GET_REFUND = "GET_REFUND";
export const GET_REFUND_SUCCESS = "GET_REFUND_SUCCESS";

import { call, put, takeLatest, select } from "redux-saga/effects";
import * as types from "./../types/setting";
import * as service from "./../services/setting";
import * as actions from "./../actions/settingActions";
import swal from "sweetalert";
import { push } from "react-router-redux";
import { authSelector } from "./../reducers";
import { del, get, post } from "../lib/Api";
import { errorResponse, successResponse } from "./auth";

function* addCommisionSettings(action) {
  let { role } = yield select(authSelector);
  const { settings, token } = action.payload;
  try {
    const result = yield call(service.addCommisionSettings, settings, token);
    if (result.status === 201) {
      yield put(actions.addCommisionSettingsSuccess(result.data.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

//Get CommisionSettings
function* getCommisionSettings() {
  try {
    const result = yield call(service.getCommisionSettings);
    if (result.status === 200) {
      yield put(actions.getCommisionSettingsSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* updatePriority(action) {
  try {
    const { options, token } = action.payload;
    const headers = { Authorization: `Bearer ${token}` };
    yield call(() =>
      post(`/deliveryOptionType/updatePriority`, { options }, headers)
    );
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* updatePriorityForOption(action) {
  try {
    const { type, options, token } = action.payload;
    const headers = { Authorization: `Bearer ${token}` };
    yield call(() =>
      post(`/deliveryOption/updatePriority`, { type, options }, headers)
    );
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* getConfig() {
  try {
    const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
    const result = yield call(() => get(`/config`, headers));
    yield put(actions.getConfigSuccess(result?.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* setConfig({ payload, token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => post(`/config`, payload, headers));
    yield put(actions.setConfigSuccess(result?.data));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
  }
}

export default function* settingWatcher() {
  yield takeLatest(types.ADD_COMMISSION_SETTING, addCommisionSettings);
  yield takeLatest(types.GET_COMMISSION_SETTING, getCommisionSettings);
  yield takeLatest(types.UPDATE_PRIORITY, updatePriority);
  yield takeLatest(types.UPDATE_PRIORITY_FOR_OPTION, updatePriorityForOption);
  yield takeLatest(types.GET_CONFING, getConfig);
  yield takeLatest(types.SET_CONFING, setConfig);
}

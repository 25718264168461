import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";

function DialogModal({ onClose, onConfirm, title, children }) {
  return (
    <Modal
      size="md"
      show={true}
      onHide={onClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header className="bg-customized border-0">
        <Modal.Title className="w-100 text-center">{title}</Modal.Title>
        <Close className="cursor-pointer" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="!flex flex-col min-[400px]:flex-row flex-nowrap">
        <Button className="w-full btn btn-primary" onClick={onConfirm}>
          Yes
        </Button>
        <Button className="w-full btn btn-secondary" onClick={onClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DialogModal;

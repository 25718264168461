import React, { useRef, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Close } from "@material-ui/icons";

const PreviewModal = ({ productInfo, onHide }) => {
  const { productPic, video, name, description, price, finalPrice } =
    productInfo;
  const carouselRef = useRef(null);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const images = [];
  if (productPic != null && productPic?.length > 0) {
    productPic.map((pic) => {
      images.push({
        ...pic,
        url: pic?.url ?? pic?.img,
      });
    });
  }

  if (video) {
    images.push({
      ...video,
      video: true,
    });
  }

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <Modal centered show={true} onHide={onHide}>
      <Modal.Header className="justify-end">
        <Close className="cursor-pointer" onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ background: "rgb(243 244 246)", borderRadius: 5 }}
          className="position-relative d-flex align-items-center justify-content-center overflow-hidden"
        >
          <Carousel
            ref={carouselRef}
            activeIndex={index}
            controls={false}
            interval={null}
            indicators={false}
            prevLabel=""
            nextLabel=""
            onSelect={handleSelect}
          >
            {images?.map(({ url }) => {
              return (
                <Carousel.Item key={url} className="cursor-pointer">
                  <img
                    src={
                      url
                        ? url
                        : "https://www.rkmarblesindia.com/wp-content/uploads/2021/03/Ruby-red-Granite-4.jpg"
                    }
                    alt="Product Image"
                    className="image-cover"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div onClick={handlePrev} className="navigate-button left">
            <FaChevronLeft fill="white" size={20} />
          </div>
          <div onClick={handleNext} className="navigate-button right">
            <FaChevronRight fill="white" size={20} />
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <div>
            <div
              className="text-lg font-semibold"
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <div
              className="text-base mt-2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="price-tag">
            Price:
            <div className="prices">
              <div className="price font-semibold line-through text-red-500">
                ${price}
              </div>
              <div className="finalPrice">${finalPrice}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewModal;

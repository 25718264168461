import { Skeleton } from '@material-ui/lab';
import React from 'react';

const CategorySkeleton = () => {
  return (
    <tr>
      <td><Skeleton variant="text" /></td>
      <td><Skeleton variant="text" /></td>
      <td><Skeleton variant="text" /></td>
    </tr>
  )
}

export default CategorySkeleton
import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { Chip } from "@material-ui/core";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";
import Modal from "./../../components/Modal/ProductModal";
import { allStatus, feeCalculator } from "../../utilities";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderItem: null,
      refund: {
        reason: "",
        amount: 0,
      },
    };
  }
  convertDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  handleInputChange = ({ currentTarget: input }) => {
    let refund = { ...this.state.refund };
    refund[input.name] = input.value;
    this.setState({ refund });
  };

  handleRefund = () => {
    const { orderItem, refund } = this.state;
    const payload = {
      ...refund,
      orderItemId: orderItem?._id,
    };
    this.props.handleRefund(payload);
    this.setState({ modalIsOpen: false });
  };

  handleClickActivity = (orderId) => {
    this.props.history.push(`/dashboard/orders/activity/${orderId}`, orderId);
  };

  render() {
    const { orders, openModal, start, end, config } = this.props;

    return (
      <tbody>
        {orders &&
          orders.slice(start, end).map((item) => {
            const addons = item?.addons ?? [];
            const addonTotal = addons?.reduce(
              (total, item) => total + item?.totalPrice,
              0
            );

            const marketingFee = feeCalculator({
              percent: config?.marketingFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const maintenanceFee = feeCalculator({
              percent: config?.maintenanceFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const technologyFee = feeCalculator({
              percent: config?.technologyFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const workerFund = feeCalculator({
              percent: config?.workerFund,
              total: item?.totalPrice,
              addonTotal,
            });

            const orderTotal = (item?.totalPrice + (addonTotal || 0)).toFixed(
              2
            );

            const vendorOrderTotal = (
              (orderTotal || 0) -
              (marketingFee + maintenanceFee + technologyFee + workerFund)
            ).toFixed(2);

            const grossProfit = Math.abs(
              Math.floor(
                orderTotal > parseInt(vendorOrderTotal)
                  ? orderTotal - parseInt(vendorOrderTotal)
                  : parseInt(vendorOrderTotal) - orderTotal
              )
            );

            return (
              <tr key={item._id}>
                <td
                  className="text-blue cursor-pointer"
                  onClick={() => this.handleClickActivity(item?._id)}
                >
                  #{item?.orderNo}
                </td>
                <td>
                  <span style={{ color: "#8e2b14", fontSize: "16px" }}>
                    {item?.user?.firstName || item?.user?.email}
                    <br />
                    {item?.user?.lastName}
                  </span>
                </td>
                <td>{item?.shippingAddressInfo?.address}</td>
                <td>{item?.shippingAddressInfo?.phone}</td>
                <td>
                  <span
                    style={{
                      color: "#fb6340",
                      fontSize: "15px",
                    }}
                  >
                    <b> ${orderTotal}</b> <br />
                  </span>
                  Via PayPal
                </td>
                <td align="center">
                  <span
                    style={{
                      color: "#fb6340",
                      fontSize: "15px",
                    }}
                  >
                    <b> ${grossProfit}</b> <br />
                  </span>
                </td>
                <td>
                  {allStatus.map(
                    (s) =>
                      item?.status === s.status && (
                        <Chip
                          style={{ background: s.color, color: "white" }}
                          label={s?.label}
                        />
                      )
                  )}
                </td>
                <td>{this.convertDate(item.createdAt)}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light kb-topmenu"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() =>
                          this.setState({
                            orderItem: item,
                            refund: { amount: item.totalPrice },
                            modalIsOpen: true,
                          })
                        }
                      >
                        Refund
                      </DropdownItem>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => {
                          openModal(item._id);
                        }}
                      >
                        Details
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        <Modal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false })}
          product={this.props.orderItems}
          image={"refund"}
          handleInputChange={this.handleInputChange}
          handleRefund={this.handleRefund}
          refund={this.state.refund}
          loading={this.props.payment.status.isLoading}
        />
      </tbody>
    );
  }
}

export default LoaderHOC("orders", 7)(OrderList);

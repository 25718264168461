import { Skeleton } from '@material-ui/lab';
import React from 'react';

const MediaSkeleton = () => {
  return (
    <tr>
      <td><Skeleton variant="rect" width={60} height={60} /></td>
      <td><Skeleton variant="text" /></td>
      <td><Skeleton variant="text" /></td>
    </tr>
  )
}

export default MediaSkeleton
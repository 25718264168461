import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Table } from "reactstrap";
import { customDate } from "../../../utilities";

const StoreOrders = ({
  auth,
  vendorInfo,
  vendorOrders,
  vendorOrdersLoading,
  getVendorOrders,
}) => {
  useEffect(() => {
    vendorInfo?._id && getVendorOrders(vendorInfo?._id, auth?.token);
  }, []);

  return vendorOrdersLoading ? (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "200px" }}
    >
      <Spinner animation="border" role="status" />
    </div>
  ) : (
    <div
      className="d-flex w-100"
      style={{ overflowX: "scroll", overflowY: "hidden" }}
    >
      <Table>
        <thead>
          <tr className="p-3">
            <th
              scope="col"
              align="left"
              className="font-weight-bold text-primary"
              style={{ fontSize: "16px" }}
            >
              Order
            </th>
            <th
              scope="col"
              align="left"
              className="font-weight-bold text-primary"
              style={{ fontSize: "16px" }}
            >
              Billing Address
            </th>
            <th
              scope="col"
              align="left"
              className="font-weight-bold text-primary"
              style={{ fontSize: "16px" }}
            >
              Shipping Address
            </th>
            <th
              scope="col"
              align="left"
              className="font-weight-bold text-primary"
              style={{ fontSize: "16px" }}
            >
              Gross Sales
            </th>
            <th
              scope="col"
              align="left"
              className="font-weight-bold text-primary"
              style={{ fontSize: "16px" }}
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {vendorOrders?.map((order) => (
            <tr className="p-3">
              <th scope="col" align="left" style={{ fontSize: "12px" }}>
                #{order?.orderNo}
              </th>
              <th scope="col" align="left" style={{ fontSize: "12px" }}>
                {order?.billingAddressInfo?.address}
              </th>
              <th scope="col" align="left" style={{ fontSize: "12px" }}>
                {order?.shippingAddressInfo?.address}
              </th>
              <th scope="col" align="left" style={{ fontSize: "12px" }}>
                ${order?.totalPrice}
              </th>
              <th scope="col" align="left" style={{ fontSize: "12px" }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: customDate(order?.createdAt),
                  }}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StoreOrders;

export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const GET_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const HIDE_CATEGORY = "HIDE_CATEGORY";
export const HIDE_CATEGORY_SUCCESS = "HIDE_CATEGORY_SUCCESS";
export const ADD_UPDATE_CATEGORY_FAILED = "ADD_UPDATE_CATEGORY_FAILED";

export const UPDATE_CATEGORY_PRIORITY = "UPDATE_CATEGORY_PRIORITY";
export const UPDATE_CATEGORY_PRIORITY_SUCCESS = "UPDATE_CATEGORY_PRIORITY_SUCCESS";

export const TRASH_CATEGORY = "TRASH_CATEGORY";
export const TRASH_CATEGORY_SUCCESS = "TRASH_CATEGORY_SUCCESS";

export const RESET_CATEGORY = "RESET_CATEGORY";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";

function ConfirmationModal({ onClose, onConfirm, title }) {
  return (
    <Modal
      className="confirmation-modal"
      size="md"
      show={true}
      onHide={onClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header className="bg-customized border-0" onClick={onClose}>
        <Modal.Title className="w-100 text-center">Confirmation</Modal.Title>
        <Close className="cursor-pointer" />
      </Modal.Header>
      <Modal.Body>
        <div className="text-md font-semibold text-center">{title}</div>
      </Modal.Body>
      <Modal.Footer className="btn-groups">
        <Button className="yes" onClick={onConfirm}>
          Yes
        </Button>
        <Button className="no" variant="secondary" onClick={onClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;

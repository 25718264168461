import * as types from "./../types/category";

export const getCategories = (query, token) => {
  return {
    type: types.GET_CATEGORIES,
    query,
    token,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: types.GET_CATEGORIES_SUCCESS,
    categories: data,
  };
};

export const hideCategory = (id, category, token) => {
  return {
    type: types.HIDE_CATEGORY,
    payload: { id, category, token },
  };
};
export const hideCategorySuccess = (payload) => {
  return {
    type: types.HIDE_CATEGORY_SUCCESS,
    payload,
  };
};

export const addCategory = (category, token) => {
  return {
    type: types.ADD_CATEGORY,
    payload: { category, token },
  };
};
export const addCategorySuccess = (payload) => {
  return {
    type: types.ADD_CATEGORY_SUCCESS,
    payload,
  };
};

export const deleteCategory = (id, token) => {
  return {
    type: types.DELETE_CATEGORY,
    payload: { id, token },
  };
};
export const deleteCategorySuccess = (payload) => {
  return {
    type: types.DELETE_CATEGORY_SUCCESS,
    payload,
  };
};

export const addUpdateCategoryFailed = (message) => {
  return {
    type: types.ADD_UPDATE_CATEGORY_FAILED,
    message,
  };
};

export const updateCategory = (id, category, token) => {
  return {
    type: types.UPDATE_CATEGORY,
    payload: { id, category, token },
  };
};

export const updateCategorySuccess = (payload) => {
  return {
    type: types.UPDATE_CATEGORY_SUCCESS,
    payload,
  };
};

export const updateCategoryPriority = (categories, token) => {
  return {
    type: types.UPDATE_CATEGORY_PRIORITY,
    payload: { categories, token },
  };
};
export const updateCategoryPrioritySuccess = () => {
  return {
    type: types.UPDATE_CATEGORY_PRIORITY_SUCCESS,
  };
};

export const trashCategory = (id, payload, token) => {
  return {
    type: types.TRASH_CATEGORY,
    id,
    payload,
    token,
  };
};

export const trashCategorySuccess = (payload) => {
  return {
    type: types.TRASH_CATEGORY_SUCCESS,
    payload,
  };
};

export const resetCategory = () => ({
  type: types.RESET_CATEGORY,
});

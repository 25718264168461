import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import "./style.css";

const ShowEnteries = (props) => {
  return (
    <FormGroup className="d-flex align-items-center mb-0">
      <Label className="lable">Show</Label>
      <Input
        type="select"
        className="entries"
        name="entries"
        id="entries"
        onChange={(e) => props.onChangeLimit(e.target.value)}
      >
        <option>25</option>
        <option>50</option>
        <option>55</option>
        <option>100</option>
      </Input>
      <Label className="lable">Enteries</Label>
    </FormGroup>
  );
};
export default ShowEnteries;

import API, { HeadersOrigin } from "./../lib/Api";

export async function getAdminPayment() {
  return API.get(`/payment`, { headers: HeadersOrigin });
}
export async function getVendorPayment(vendorId) {
  return API.get(`/payment/vendor/${vendorId}`, { headers: HeadersOrigin });
}

export function vendorRefund(payload) {
  return API.post(
    `/payment/vendor/refund`,
    { payload },
    { headers: HeadersOrigin }
  );
}

export function getVendorRefund(id) {
  return API.get(`/payment/vendor/refund/${id}`, { headers: HeadersOrigin });
}

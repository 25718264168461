import React, { useRef, useState } from "react";
import { Camera as CameraPro } from "react-camera-pro";
import { MdCameraswitch } from "react-icons/md";
import { Camera as CameraIcon } from "@material-ui/icons";
import { base64ToFile } from "../../utilities";

const Camera = ({ onFileUpload, showCamera, frameSize, onClose }) => {
  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  const onCloseModal = () => {
    setNumberOfCameras(0);
    onClose();
  };

  const onUploadImage = async (image) => {
    onCloseModal();
    const file = await base64ToFile(
      image,
      `media-${Date.now()}.jpg`,
      "image/jpeg"
    );
    onFileUpload(file);
  };

  return showCamera ? (
    <div className="camera-container" style={{ ...frameSize }}>
      <CameraPro
        ref={camera}
        facingMode="user"
        numberOfCamerasCallback={setNumberOfCameras}
      />
      <div className="icon-wrapper">
        <CameraIcon
          style={{ color: "#fff", fontSize: "50px" }}
          onClick={() => {
            const photo = camera.current.takePhoto();
            onUploadImage(photo);
          }}
        />
        {numberOfCameras > 1 && (
          <MdCameraswitch
            className="switch-icon"
            onClick={() => camera.current.switchCamera()}
          />
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Camera;

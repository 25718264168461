import React from "react";
import { Link } from "react-router-dom";
import GoogleMap from "../GoogleMap";
import { icons } from "../CustomizedActivity";
import { formatDate } from "../../../../utilities";

const Map = (props) => {
  const { type, text, client, vendor, date } = props ?? {};

  const clientLocation = client?.location?.coordinates;
  const vendorLocation = vendor?.location?.coordinates;

  const googleMapsUrl = `https://www.google.com/maps/dir/${clientLocation?.[1]},${clientLocation?.[0]}/${vendorLocation?.[1]},${vendorLocation?.[0]}`;

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type]?.icon && icons?.[type]?.icon}
        <div className="detail-wrapper">
          <div className="mb-2">
            <div>
              <div className="detail">{text}</div>
              <div className="date">{formatDate(date)}</div>
            </div>
            <span>
              <a
                href={googleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue text-nowrap"
              >
                View on Google Maps
              </a>
            </span>
          </div>
          <GoogleMap client={client} vendor={vendor} />
        </div>
      </div>
    </div>
  );
};

export default Map;

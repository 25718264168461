import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Breadcrumb, Container, Spinner } from "react-bootstrap";
import { Chip } from "@material-ui/core";

import InvoiceDialog from "../Invoice";
import CustomizedActivity from "./CustomizedActivity";
import PreviewModal from "../PreviewModal";
import * as orderActions from "../../../store/actions/orderActions";
import "./activity.scss";
import { get } from "../../../store/lib/Api";
import { feeCalculator } from "../../../utilities";
import { getConfig } from "../../../store/actions/settingActions";

const OrderActivity = ({ location }) => {
  const dispatch = useDispatch();
  const [dataHtml, setDataHtml] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.setting);
  const { singleOrder } = useSelector((state) => state.order) ?? {};
  const { orderActivity, orderNo, totalPrice, product, user, owner, addons } =
    singleOrder ?? {};

  const id = location?.pathname.split("/").at(-1);

  useEffect(() => {
    dispatch(getConfig());
    dispatch(orderActions.getSingleOrder(id, token));
  }, [id]);

  const handleGeneratePdf = async (isClient) => {
    try {
      setLoading(true);
      const downloadInvoice = await get(
        `/order/downloadInvoice/${id}?userType=${
          isClient ? "client" : "vendor"
        }&accessedBy=admin`,
        { Authorization: `Bearer ${token}` }
      );

      setDataHtml(downloadInvoice);
      setShowInvoice(true);
      setLoading(false);
    } catch (error) {
      swal({
        title: "Error",
        text: error.message,
        icon: "error",
      });
      setLoading(false);
      setShowInvoice(false);
    }
  };

  const addonTotal = addons?.reduce(
    (total, item) => total + item?.totalPrice,
    0
  );

  const marketingFee = feeCalculator({
    percent: config?.marketingFee,
    total: totalPrice,
    addonTotal,
  });
  const maintenanceFee = feeCalculator({
    percent: config?.maintenanceFee,
    total: totalPrice,
    addonTotal,
  });
  const technologyFee = feeCalculator({
    percent: config?.technologyFee,
    total: totalPrice,
    addonTotal,
  });
  const workerFund = feeCalculator({
    percent: config?.workerFund,
    total: totalPrice,
    addonTotal,
  });

  const orderTotal = (totalPrice + (addonTotal || 0)).toFixed(2);

  const vendorOrderTotal = (
    (orderTotal || 0) -
    (marketingFee + maintenanceFee + technologyFee + workerFund)
  ).toFixed(2);

  const grossProfit = Math.abs(
    Math.floor(
      orderTotal > parseInt(vendorOrderTotal)
        ? orderTotal - parseInt(vendorOrderTotal)
        : parseInt(vendorOrderTotal) - orderTotal
    )
  );

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "100vh" }}
      >
        <Spinner height={50} width={50} animation="border" role="status" />
      </div>
    );
  }

  return (
    <>
      <Container className="activity mt-5">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard/orders">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            #{orderNo}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-between align-items-start mt-3">
          <div className="d-flex w-100">
            {product?.productPic?.[0]?.url && (
              <img
                style={{ maxHeight: 150, maxWidth: 150, borderRadius: 10 }}
                src={product?.productPic?.[0]?.url}
                alt={product?.sku}
              />
            )}
            <div className="ml-3 d-flex flex-column justify-content-between align-items-start w-100">
              <div className="d-flex flex-column justify-content-between align-items-start w-100">
                <div
                  onClick={() => setProductInfo(product)}
                  className="cursor-pointer font-weight-bold"
                  dangerouslySetInnerHTML={{ __html: product?.name }}
                />
              </div>
            </div>
          </div>
          <Chip
            variant="outlined"
            style={{ fontSize: 15 }}
            label={`Gross Profit: $${grossProfit}`}
          />
        </div>
        <div className="d-flex mt-2" style={{ gap: "10px" }}>
          <div style={{ width: "48%" }}>
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                <b>
                  Customer ({user?.firstName} {user?.lastName})
                </b>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="text-lg">${orderTotal}</div>
                <div
                  className="invoice"
                  onClick={() => handleGeneratePdf(true)}
                >
                  View Invoice
                </div>
              </div>
            </div>
            {orderActivity?.map((activity, i) => (
              <CustomizedActivity
                key={i}
                forTheClient={true}
                orderDetails={singleOrder}
                activity={activity}
                product={product}
              />
            ))}
          </div>
          <div style={{ width: "48%" }}>
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                <b>
                  Vendor ({owner?.firstName} {owner?.lastName})
                </b>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="text-lg">${vendorOrderTotal}</div>
                <div
                  className="invoice"
                  onClick={() => handleGeneratePdf(false)}
                >
                  View Invoice
                </div>
              </div>
            </div>
            {orderActivity?.map((activity, i) => (
              <CustomizedActivity
                key={i}
                forTheVendor={true}
                orderDetails={singleOrder}
                activity={activity}
                product={product}
              />
            ))}
          </div>
        </div>
        {productInfo && (
          <PreviewModal
            onHide={() => setProductInfo(null)}
            productInfo={productInfo}
          />
        )}
      </Container>
      {showInvoice && (
        <InvoiceDialog
          dataHtml={dataHtml}
          showModel={showInvoice}
          onClose={() => {
            setShowInvoice(false);
            setDataHtml("");
          }}
        />
      )}
    </>
  );
};

export default OrderActivity;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import * as settingActions from "../../../store/actions/settingActions";
import OptionRow from "./optionRow";

const OptionSection = ({
  reorder,
  section,
  options,
  optionsType,
  loading,
  token,
}) => {
  let dispatch = useDispatch();
  let [addOption, setAddOption] = useState(false);
  let [uploadItem, setUploadItem] = useState({
    mode: "",
    iconSrc: "",
    price: "",
  });
  let handleUploadChange = (e) => {
    setUploadItem({
      ...uploadItem,
      [e.target.name]: e.target.value,
    });
  };

  //uploadImage
  let updateImage = async (event) => {
    let imageFile = event.target.files[0];
    let base64Image = await convertBase64String(imageFile);
    setUploadItem({
      ...uploadItem,
      iconSrc: base64Image,
    });
  };
  let convertBase64String = (imageFile) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(imageFile);
      fileReader.addEventListener("load", () => {
        if (fileReader.result) {
          resolve(fileReader.result);
        } else {
          reject("Error Occupied");
        }
      });
    });
  };

  let uploadProduct = (e) => {
    e.preventDefault();
    let obj = {
      ...uploadItem,
      type: section._id,
    };
    dispatch(settingActions.addOption(obj, token));
  };
  let sectionDelete = (id) => {
    dispatch(settingActions.deleteSection(id, token));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to DELETE !</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              sectionDelete(section._id);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-delete-wrapper">
        <div className="section-title">SECTION: {section.name}</div>
        <div className="section-delete" onClick={handleShow}>
          DELETE
        </div>
      </div>
      <div className="enable-disable-block-wrapper">
        <div className="border-top-custom" />
        <div className="enable-disable-block">
          <div className="enable-disable-btn">Enable / Disable Block</div>
          <div className="switch-btn-wrapper"></div>
        </div>
        <div className="target-location-wrapper">
          <div className="target-location">TARGET LOCATION:</div>
          <div className="include-exclude-wrapper">
            <div className="target-include">INCLUDE</div>
            <div className="target-exclude">EXCLUDE</div>
          </div>
        </div>
      </div>

      <Droppable droppableId={section?._id} type="subOption">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className="options-container-wrapper">
              <div className="option-wrapper-title">{section.name}</div>
              <div className="options-container">
                <table className="table table-borderless">
                  <tbody>
                    {optionsType?.map((option, index) => {
                      return (
                        <Draggable
                          isDragDisabled={loading}
                          draggableId={option?._id}
                          key={option?._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onMouseDown={(e) => e.stopPropagation()}
                            >
                              <OptionRow option={option} token={token} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  className="add-option-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddOption((prevState) => !prevState);
                  }}
                >
                  Add Option
                </div>
                <table className="table table-borderless">
                  {addOption ? (
                    <tbody> addOption </tbody>
                  ) : (
                    <tbody>
                      <tr className="d-flex justify-content-between">
                        <td>
                          <input
                            value={uploadItem.mode}
                            onChange={handleUploadChange}
                            name="mode"
                            placeholder={"option name"}
                            type="text"
                            className={"form-control"}
                          />
                        </td>
                        <td>
                          <div className="form-group">
                            <div className="custom-file">
                              <input
                                onChange={updateImage}
                                type="file"
                                className="form-control"
                                id="customFile"
                              />
                            </div>
                          </div>
                        </td>
                        <td className="d-flex justify-content-end">
                          <input
                            className="price-tag form-control"
                            value={uploadItem.price}
                            onChange={handleUploadChange}
                            name="price"
                            type="text"
                            placeholder={"price"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="d-flex justify-content-end mt-2"
                        >
                          <button
                            className="btn btn-sm btn-success"
                            onClick={uploadProduct}
                          >
                            Upload
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <div className="border-top-custom" />
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default OptionSection;

import { mixed, object, string } from "yup";

export const userValidationSchema = (updating) => {
  let schema = {
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().email().required("Email is required"),
    password: string().required("Password is required"),
    address: string().required("Address is required"),
    accesses: mixed().required("Accesses is required"),
  };
  if (updating) {
    schema = { ...schema, password: string().optional() };
  }
  return object().shape(schema);
};

export const vendorValidationSchema = (updating) => {
  let schema = {
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().email().required("Email is required"),
    password: string().required("Password is required"),
    storeName: string().required("Store name is required"),
    contact: string().required("Contact is required"),
    managerContact: string().required("Manager Contact is required"),
    storeAddress: string().required("Store Address is required"),
  };

  if (updating) {
    schema = { ...schema, password: string().optional() };
  }
  return object().shape(schema);
};

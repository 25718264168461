import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { TiImage } from "react-icons/ti";

import Header from "./../../components/Headers/DefaultHeader.jsx";
import SearchField from "./../../components/SearchFields";
import SelectorField from "./../../components/SearchFields/StoreSelector";
import Modal from "../../components/Modal/ArticleModal";
import Paginator from "../Pagination";
import ArticleList from "./ArticleList";
import * as articleAction from "./../../store/actions/articleActions";
import * as Const from "../../utilities";
import Loading from "../../utilities/loading.js";
import { showMessageError } from "../../utilities/errorHandle";
import DialogModal from "../../components/Modal/DialogModal.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      articles: this.props.article.articles,
      modalIsOpen: false,
      article: {},
      imageModal: "",
      searching: false,
      page: 1,
      perPage: 10,
      order: "desc",
      orderBy: "date",
      flagDialog: false,
      selectedArticle: null,
      note: null,
      deleteDialog: false,
    };
    this.componentWillReceiveProps = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.article.articles !== prevState.articles &&
      !prevState.searching
    ) {
      return { articles: nextProps.article.articles };
    }

    if (nextProps?.aritcle?.loading !== prevState?.artical?.loading) {
      return { loading: nextProps?.aritcle?.loading };
    }

    if (nextProps?.aritcle?.error !== prevState?.artical?.error) {
      if (
        nextProps?.aritcle?.error &&
        nextProps?.aritcle?.error.toString() != ""
      ) {
        showMessageError(nextProps?.aritcle?.error.toString());
      }
      return { errorMsg: nextProps?.aritcle?.error };
    }

    return null;
  }

  componentDidMount() {
    this.props.getArticle({ deleted: false });

    this.setState({ articles: this.props.article.articles });
    this.handleStoreNames();
  }

  paginationHandler = (page) => {
    this.setState({ page });
  };

  handleStoreNames = () => {
    const { articles } = this.props.article;
    const filterStores = [];
    articles.map((e) =>
      e.owner?.storeName ? filterStores.push(e.owner) : null
    );
    const stores = Array.from(new Set(filterStores.map((a) => a._id))).map(
      (id) => {
        return filterStores.find((a) => a._id === id);
      }
    );
    this.setState({ stores });
  };
  componentWillReceiveProps = (nextProps) => {
    const articles = nextProps.article.articles;
    this.setState({ articles });
  };

  openModal = (article) => {
    this.setState({ modalIsOpen: true, article });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleAddFlag = (article) => {
    const { selectedArticle, note } = this.state;
    const { token } = this.props.auth;
    if (article) {
      this.props.addFlag(article?._id, { flag: !article?.flag, note }, token);
    } else {
      this.props.addFlag(
        selectedArticle?._id,
        { flag: !selectedArticle?.flag, note },
        token
      );
    }
    this.setState({ flagDialog: false });
  };

  handleDelete = () => {
    const { selectedArticle } = this.state;
    const { token } = this.props.auth;
    this.props.trashArticle(selectedArticle._id, { deleted: true }, token);
    this.handleClose();
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  changeSearch = ({ target: input }) => {
    const articles = this.props.article.articles;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterArticles = articles.filter((el) => {
        return el.name.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ articles: filterArticles });
    } else {
      this.setState({ articles: this.props.article.articles });
    }
  };

  handleEdit = (artcleId) => {
    this.props.history.push("/dashboard/add-article", artcleId);
  };

  handleAddArticle = () => {
    this.props.history.push("/dashboard/add-article");
  };

  addView = (id) => {
    const { token } = this.props.auth;
    // this.props.addView(id, token);
  };

  publishArticle = (id) => {
    const { token } = this.props.auth;
    this.props.publishArticle(id, token);
  };

  nameSearch = (value) => {
    const { articles } = this.props.article;
    if (value) {
      let searchText = value.toLowerCase();
      const filter = articles.filter((article) => {
        return article.name.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ articles: filter, searching: true });
    } else {
      this.setState({
        articles: this.props.article.articles,
        searching: false,
      });
    }
  };
  storeSelect = ({ target: input }) => {
    const { articles } = this.props.article;
    if (input.value && input.value !== "") {
      const filter = articles.filter((e) => e.owner?._id === input.value);
      this.setState({ articles: filter, searching: true });
    } else {
      this.setState({
        articles: this.props.article.articles,
        searching: false,
      });
    }
  };
  filterSelect = (input) => {
    const { articles } = this.props.article;
    if (input?.value === "publish") {
      const filter = articles.filter((e) => e.isPublished);
      this.setState({ articles: filter, searching: true });
    } else if (input?.value === "pending") {
      const filter = articles.filter((e) => !e.isPublished);
      this.setState({ articles: filter, searching: true });
    } else {
      this.setState({
        articles: this.props.article.articles,
        searching: false,
      });
    }
  };

  handleArticle = () => {
    const { role } = this.props.auth;
    this.props.history.push(`/dashboard/add-article`);
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  createData(
    _id,
    cover,
    name,
    flag,
    note,
    isPublished,
    views,
    likes,
    category,
    date,
    slug
  ) {
    return {
      _id,
      cover,
      name,
      flag,
      note,
      isPublished,
      views,
      likes,
      category,
      date,
      statusValue: isPublished ? 0 : 1,
      slug,
    };
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getComparator(order, orderBy) {
    if (orderBy === "isPublished") {
      return (a, b) => {
        const aValue = a[orderBy] ? 0 : 1;
        const bValue = b[orderBy] ? 0 : 1;

        return order === "desc" ? bValue - aValue : aValue - bValue;
      };
    }
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  render() {
    const { articles, stores, order, orderBy, page, perPage } = this.state;
    const { role } = this.props.auth;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    const headCells = [
      {
        id: "name",
        numeric: false,
        label: "Name",
      },
      {
        label: `Flag an \n inappropriate`,
      },
      {
        label: "Note",
      },
      {
        id: "isPublished",
        numeric: false,
        label: "Status",
        sortingId: "statusValue",
      },
      { id: "views", numeric: true, label: "Views" },
      { id: "likes", numeric: true, label: "Likes" },
      { id: "category", numeric: false, label: "Category" },
      { id: "date", numeric: false, label: "Date" },
    ];

    const rows = articles.map((item) =>
      this.createData(
        item._id,
        item.cover,
        item.name,
        item.flag,
        item?.note ?? "",
        item.isPublished,
        item.views,
        item.likes,
        item.category?.name,
        item.date,
        item.slug
      )
    );

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Articles</h3>
                </CardHeader>
                <div className="HeaderWrapper align-items-center flex-wrap">
                  <SearchField onChange={this.nameSearch} />
                  <div style={{ width: "170px" }}>
                    <ReactSelect
                      options={Const.articleSelector}
                      isClearable={true}
                      onChange={this.filterSelect}
                    />
                  </div>
                  {role === "admin" && (
                    <SelectorField
                      data={stores}
                      placeholder="Select Store"
                      changeSelect={this.storeSelect}
                    />
                  )}
                  <Button onClick={this.handleArticle} type="button">
                    Add Article
                  </Button>
                </div>
                <div className="position-relative">
                  <Loading
                    width={70}
                    height={70}
                    loading={this.props.loading}
                    className="kb-loading-table"
                  />
                  <Table
                    className={`${
                      this.props.loading ? "kb-overlay" : ""
                    } "align-items-center table-flush"`}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          <TiImage size={"20px"} />
                        </th>
                        {headCells.map((cell) => (
                          <th scope="col">
                            {cell.id ? (
                              <div
                                style={{ fontSize: "14px" }}
                                onClick={(event) =>
                                  this.handleRequestSort(
                                    event,
                                    cell?.sortingId ?? cell.id
                                  )
                                }
                                className="d-flex align-items-center cursor-pointer"
                              >
                                {cell.label}{" "}
                                {orderBy === cell.id ||
                                orderBy === cell.sortingId ? (
                                  <div className="d-flex flex-column ml-1">
                                    <i
                                      className="fas fa-play"
                                      style={{
                                        fontSize: "8px",
                                        transform: "rotate(-90deg)",
                                        visibility:
                                          (orderBy === cell.id ||
                                            orderBy === cell.sortingId) &&
                                          order === "asc"
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    />
                                    <i
                                      className="fas fa-play"
                                      style={{
                                        fontSize: "8px",
                                        transform: "rotate(90deg)",
                                        visibility:
                                          (orderBy === cell.id ||
                                            orderBy === cell.sortingId) &&
                                          order === "desc"
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex flex-column justify-content-center align-items-center ml-1">
                                    <i
                                      className="fas fa-play mb-1"
                                      style={{
                                        marginLeft: "2px",
                                        fontSize: "8px",
                                        transform: "rotate(-90deg)",
                                        opacity: "0.3",
                                      }}
                                    />
                                    <i
                                      className="fas fa-play"
                                      style={{
                                        fontSize: "8px",
                                        transform: "rotate(90deg)",
                                        opacity: "0.3",
                                        marginLeft: "2px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "14px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {cell.label}
                              </div>
                            )}
                          </th>
                        ))}
                        <th></th>
                        <th style={{ fontSize: "14px" }} scope="col">
                          ACTIONS
                        </th>
                      </tr>
                    </thead>
                    <ArticleList
                      articles={this.stableSort(
                        rows,
                        this.getComparator(order, orderBy)
                      ).slice(startIndex, endIndex)}
                      publishArticle={this.publishArticle}
                      openModal={this.openModal}
                      handleAddFlag={(selectedArticle) => {
                        if (selectedArticle?.flag) {
                          this.handleAddFlag(selectedArticle);
                        } else {
                          this.setState({ flagDialog: true, selectedArticle });
                        }
                      }}
                      handleDelete={(selectedArticle) =>
                        this.setState({ deleteDialog: true, selectedArticle })
                      }
                    />
                  </Table>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      onPageChange={this.paginationHandler}
                      total={articles?.length}
                    />
                  </nav>
                </CardFooter>
              </Card>
              <Modal
                modalIsOpen={this.state.modalIsOpen}
                closeModal={this.closeModal}
                article={this.state.article}
                handleDelete={(selectedArticle) =>
                  this.setState({ deleteDialog: true, selectedArticle })
                }
                handleEdit={this.handleEdit}
              />
            </div>
          </Row>
        </Container>
        {this.state.deleteDialog && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={this.handleClose}
            onConfirm={this.handleDelete}
          />
        )}
        {this.state.flagDialog && (
          <DialogModal
            onClose={() => this.setState({ flagDialog: false })}
            onConfirm={() => this.handleAddFlag()}
            title={"Flag an inappropriate"}
          >
            <textarea
              className="form-control"
              onChange={(e) => this.setState({ note: e.target.value })}
            />
          </DialogModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    article: state.article,
    loading: state.article.loading,
    errorMsg: state.article.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trashArticle: (id, payload, token) =>
      dispatch(articleAction.trashArticle(id, payload, token)),
    publishArticle: (id, token) =>
      dispatch(articleAction.publishArticle(id, token)),
    getArticle: (query) => dispatch(articleAction.getArticle(query)),
    addView: (id, token) => dispatch(articleAction.addView(id, token)),
    addFlag: (id, payload, token) =>
      dispatch(articleAction.addFlag(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);

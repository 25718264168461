import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Header from "./../../components/Headers/DefaultHeader.jsx";
import { connect } from "react-redux";
// import ProductSearchField from "./../../components/SearchFields/ProductSearchField";
import * as orderActions from "./../../store/actions/orderActions";
import * as settingActions from "./../../store/actions/settingActions";
import * as paymentActions from "./../../store/actions/paymentActions";
// import * as Const from "../../utilities";
// import { FaInfoCircle, FaEllipsisH } from "react-icons/fa";
// import { TiDeleteOutline } from "react-icons/ti";
import Modal from "./../../components/Modal/ProductModal";
import OrderList from "./OrderList";
import Paginator from "../Pagination";
import "./order.scss";
import { Button } from "react-bootstrap";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      product: {},
      order: {},
      page: 1,
      perPage: 10,
      searchInput: "",
      orders: [],
    };
    this.handleRefund = this.handleRefund.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  componentDidMount() {
    const { auth, getOrder, getConfig } = this.props;
    getOrder(auth?.token);
    getConfig();
  }
  componentWillReceiveProps(nextProps) {
    nextProps.order.orders && this.setState({ orders: nextProps.order.orders });
  }
  openModal = (order) => {
    const isVendor =
      this.props.auth.role === "vendor" ? this.props.auth.user : "admin";
    const imageModal = "orderItems";
    this.setState({ modalIsOpen: true, order, imageModal });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleRefund = (payload) => {
    const { token } = this.props.auth;
    this.props.orderRefund(payload, token);
  };
  nameSearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter((product) => {
        return product.name.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  categorySearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value;
      const filterProducts = products.filter((product) => {
        return product.category.indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  colorSearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter((product) => {
        return product.color.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  changeSearch = ({ target: input }) => {
    const products = this.props.product.products;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter((el) => {
        return (
          el.name.toLowerCase().indexOf(searchText) >= 0 ||
          el.category.indexOf(searchText) >= 0 ||
          el.color.toLowerCase().indexOf(searchText) >= 0
        );
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  handleEdit = (orderId) => {};

  onSearch = () => {
    const { searchInput } = this.state;
    const orders = this.props.order.orders ?? [];
    if (searchInput) {
      const searchRegex = new RegExp(searchInput, "i");
      const filteredProducts = orders.filter((item) =>
        [
          item?.orderNo,
          item?.user?.firstName,
          item?.user?.lastName,
          item?.user?.email,
          item?.shippingAddressId?.address,
          item?.shippingAddressId?.phone,
          item.status,
        ].some((field) => field && searchRegex.test(field))
      );
      this.setState({ orders: filteredProducts });
    } else {
      this.setState({ orders });
    }
  };

  paginationHandler = (page) => {
    this.setState({ page });
  };

  render() {
    const { page, perPage, orders } = this.state;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="search-section d-flex justify-content-between border-0">
                  <h3 className="mb-0">Order</h3>
                  <div className="d-flex search">
                    <TextField
                      classes={{ root: "inputWrapper" }}
                      variant="outlined"
                      size="small"
                      label="Keyword search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => e.key == "Enter" && this.onSearch()}
                      onChange={(event) =>
                        this.setState({ searchInput: event.target.value })
                      }
                    />
                    <Button
                      className="ml-2"
                      onClick={this.onSearch}
                      style={{ borderRadius: "24px" }}
                    >
                      Search orders
                    </Button>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>ORDER ID</th>
                      <th>ORDER BY</th>
                      <th>SHIPPING ADDRESS</th>
                      <th>CONTACT INFO</th>
                      <th>GROSS SALE</th>
                      <th>GROSS PROFIT</th>
                      <th>STATUS</th>
                      <th>DATE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <OrderList
                    orders={orders}
                    config={this.props.config}
                    openModal={this.openModal}
                    handleRefund={this.handleRefund}
                    history={this.props.history}
                    auth={this.props.auth}
                    payment={this.props.refund}
                    start={startIndex}
                    end={endIndex}
                  />
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      total={orders ? orders?.length : 0}
                      onPageChange={this.paginationHandler}
                    />
                  </nav>
                </CardFooter>
              </Card>
              <Modal
                modalIsOpen={this.state.modalIsOpen}
                closeModal={this.closeModal}
                product={this.props.orderItems}
                image={this.state.imageModal}
                handleRefund={this.handleRefund}
                handleEdit={this.handleEdit}
              />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    order: state.order,
    category: state.category,
    orderItems: state.order.items,
    config: state.setting.config,
    refund: state.payment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(settingActions.getConfig()),
    getOrder: (token) => dispatch(orderActions.getOrder(token)),
    refundPayment: (payload) => dispatch(paymentActions.vendorRefund(payload)),
    orderRefund: (payload, token) =>
      dispatch(orderActions.orderRefund(payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

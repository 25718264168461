import swal from "sweetalert";

export const showMessageSuccess = (msg) => {
  swal({
    button: {
      text: "Close",
      closeModal: true
    },
    title: "Success",
    className: "toast_class",
    text: msg
  });
};

export const showMessageError = (msg) => {
  swal({
    button: {
      text: "Close",
      closeModal: true
    },
    title: "Error",
    className: "toast_class",
    text: msg
  });
}

export default function showError(errors) {
  let showerror = "";

  for (const e in errors) {
    showerror += errors[e] + "\n";
  }

  swal({
    button: {
      text: "Close",
      closeModal: true
    },
    title: "Error",
    className: "toast_class",
    text: showerror
  });
}

import React from "react";
import { Button, Chip } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { OrderStatus, allStatus, formatDate } from "../../../utilities";

const Item = (props) => {
  const {
    item,
    setOrderItemId,
    setShowCancelModal,
    setShowRefundModal,
    handleGeneratePdf,
    history,
  } = props;
  const product = item?.product ?? {};

  const orderTotal = (
    (item?.totalPrice || 0) + (item?.addons?.[0]?.totalPrice || 0)
  ).toFixed(2);

  const status = allStatus.find((status) => status.status === item?.status);

  return (
    <div className="item-container">
      <div className="order-section">
        <div className="product-section">
          <div className="product-container">
            {product?.productPic?.length > 0 ? (
              <img
                src={product?.productPic[0]?.url ?? product?.productPic[0]?.img}
                width={100}
                height={100}
                alt="Product Image"
              />
            ) : (
              <img
                src="https://www.rkmarblesindia.com/wp-content/uploads/2021/03/Ruby-red-Granite-4.jpg"
                className="w-100"
                alt="No found"
              />
            )}
            <div className="product-details-section">
              <div className="d-flex">
                <div
                  className="mr-2"
                  dangerouslySetInnerHTML={{ __html: product?.name }}
                />
                <Chip
                  size="small"
                  style={{
                    fontSize: 13,
                    backgroundColor: status?.color,
                    color: "white",
                  }}
                  label={status?.label}
                />
              </div>
              <span
                style={{
                  color: "#81cdc6",
                  textDecoration: "underline",
                  width: "fit-content",
                }}
                className="cursor-pointer"
                onClick={() => handleGeneratePdf(item?._id)}
              >
                View invoice
              </span>
            </div>
          </div>
        </div>
        <div className="additional-details">
          <div className="order-total">
            <div className="order-date">
              <div className="slightBold">Order Total</div>
              <div className="date-font">
                <span>${orderTotal}</span>
              </div>
            </div>
          </div>
          <div className="order-on">
            <div className="order-date">
              <div className="slightBold">Ordered On</div>
              <div className="date-font">
                <span>{formatDate(item?.createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="address-section">
        <div className="address">
          <div className="text-nowrap mr-2">Shipping Address</div>
          <p className="p-0 m-0">{item?.shippingAddressInfo?.address}</p>
        </div>
        <div className="d-flex flex-column">
          {item?.status == OrderStatus.CANCELLED ? (
            <Button
              variant="contained"
              color="secondary"
              className="activity-btn px-5 border-0 text-white mb-2"
              onClick={() => {
                setOrderItemId(item?._id);
                setShowRefundModal(true);
              }}
            >
              Refund
            </Button>
          ) : item?.status != OrderStatus.REFUNDED ? (
            <Button
              variant="contained"
              color="secondary"
              className="activity-btn px-5 border-0 text-white mb-2"
              onClick={() => {
                setOrderItemId(item?._id);
                setShowCancelModal(true);
              }}
            >
              Cancel
            </Button>
          ) : (
            <></>
          )}
          <Button
            style={{ background: "#28a99e" }}
            className="activity-btn px-5 border-0 text-white"
            onClick={() =>
              history.push(`/dashboard/orders/activity/${item?._id}`, {
                id: item?.user?._id,
                from: "user",
              })
            }
          >
            Activity
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Item);

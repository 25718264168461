import axios from "axios";
export const HeadersOrigin = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization"
};
const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // timeout: 6000
});

export const get = async (endpoint, headers) => {
  const result = await API.get(endpoint, { headers });
  return result.data;
};

export const post = async (endpoint, body, headers) => {
  const result = await API.post(endpoint, body, { headers });
  return result.data;
};

export const patch = async (endpoint, body, headers) => {
  const result = await API.patch(endpoint, body, { headers });
  return result.data;
};

export const del = async (endpoint, headers) => {
  const result = await API.delete(endpoint, { headers });
  return result.data;
};

export { axios }

export default API;



import React, { useState } from "react";
import { Collapse } from "@material-ui/core";

import RequirementData from "../RequirementData";
import { icons } from "../CustomizedActivity";
import { ACTIVITY_TYPE, formatDate } from "../../../../utilities";

const Expendable = (props) => {
  const { type, text, link, createdAt, requirements } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type]?.icon && icons?.[type]?.icon}
        <div className="detail-wrapper">
          <div className="expendable">
            <div className="expandable-details">
              <div>{text}</div>
              <span>{formatDate(createdAt)}</span>
            </div>
            {type === ACTIVITY_TYPE.REQUIREMENT_SUBMITTED ? (
              <div className="action" onClick={handleClick}>
                View requirements
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {requirements && <RequirementData requirements={requirements} />}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Expendable;

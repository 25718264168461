/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row
} from "reactstrap";
import { connect } from "react-redux";
import Header from "../../components/Headers/DefaultHeader.jsx";
import SearchField from "./../../components/SearchFields/index";
import * as productActions from "./../../store/actions/productActions";
import { FaEllipsisH } from "react-icons/fa";
import { TiImage } from "react-icons/ti";

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      modalIsOpen: false,
      product: {},
      imageModal: {}
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  componentDidMount() {
    const { token, role } = this.props.auth;
    if (role === "vendor") {
      const { user } = this.props.auth;
      this.props.getMyProducts(user);
    } else {
      this.props.getProducts(token, { deleted: false });
    }
    const products = this.props.product.products;
    this.setState({ products });
  }
  componentWillReceiveProps = nextProps => {
    const products = nextProps.product.products;
    this.setState({ products });
  };
  openModal = product => {
    const imageModal = product.productPic[0];
    this.setState({ modalIsOpen: true, product, imageModal });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleDelete = id => {
    const { token } = this.props.auth;
    this.props.deleteProduct(id, token);
    this.setState({ modalIsOpen: false });
  };
  nameSearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter(product => {
        return product.name.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  categorySearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value;
      const filterProducts = products.filter(product => {
        return product.category.name.indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  colorSearch = ({ target: input }) => {
    const { products } = this.state;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter(product => {
        return product.color.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  changeSearch = ({ target: input }) => {
    const products = this.props.product.products;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProducts = products.filter(el => {
        return (
          el.name.toLowerCase().indexOf(searchText) >= 0 ||
          el.category.name.indexOf(searchText) >= 0 ||
          el.color.toLowerCase().indexOf(searchText) >= 0
        );
      });
      this.setState({ products: filterProducts });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  handleEdit = productId => {
    this.props.history.push("/dashboard/add-product", productId);
  };

  convertDate = dt => {
    const date = new Date(dt);
    return date.toDateString();
  };

  render() {
    const products = this.props.product.products;
    const { role } = this.props.auth;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Reviews</h3>
                </CardHeader>
                {/* body will define Here */}
                <SearchField />

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">
                        {" "}
                        <TiImage size={"20px"} />
                      </th>
                      <th scope="col">
                        {" "}
                        <FaEllipsisH style={{ size: "20px", color: "green" }} />
                      </th>
                      <th scope="col">AUTHOR</th>
                      <th scope="col">COMMENT</th>
                      <th scope="col">RATING</th>
                      <th scope="col">DATED</th>
                      <th scope="col">ACTIONS</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    product: state.product
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProducts: (token) => dispatch(productActions.getProducts(token)),
    addView: (id, token) => dispatch(productActions.addView(id, token)),
    getMyProducts: user => dispatch(productActions.getMyProducts(user)),
    deleteProduct: (id, token) =>
      dispatch(productActions.deleteProduct(id, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

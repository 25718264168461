import React, { memo } from "react";
import { FormGroup, InputGroup } from "react-bootstrap";

export default memo(function ImageAdd({
  register,
  errors,
  fields,
  remove,
  append,
  defaultField,
  imageRequired,
  setFields,
}) {
  const addField = () => {
    const newDefault = { ...defaultField, _id: Date.now() };
    setFields([...fields, newDefault]);
    append(newDefault);
  };
  const removeField = (index) => {
    remove(index);
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  return (
    <div className="mt-5 mb-5">
      <label className="font-18"> Images:</label>
      <div className="border-1 p-10">
        {fields.map((field, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between">
              <span>{index + 1}#</span>
              <i
                className="fas fa-trash text-danger"
                onClick={() => removeField(index)}
              />
            </div>
            <div className="p-10 mx-40">
              <input
                type="hidden"
                value={field._id}
                {...register(`pic.${index}._id`, {
                  onChange: (e) => {
                    e.persist();
                    setFields((prevFields) => {
                      const updatedFields = [...prevFields];
                      updatedFields[index]._id = e.target.value;
                      return updatedFields;
                    });
                  },
                })}
              />

              <FormGroup className="mb-3">
                <div className="mt-10 ">
                  <label className="font-18">Image title:</label>
                </div>
                <InputGroup className="input-group-alternative">
                  <input
                    type="text"
                    placeholder="Image Title...."
                    className="input form-control"
                    value={field.title}
                    {...register(`pic.${index}.title`, {
                      onChange: (e) => {
                        e.persist();
                        setFields((prevFields) => {
                          const updatedFields = [...prevFields];
                          updatedFields[index].title = e.target?.value;
                          return updatedFields;
                        });
                      },
                    })}
                  />
                </InputGroup>
                {errors?.pic?.[index]?.title && (
                  <div className="text-danger">
                    {errors?.pic?.[index]?.title?.message}
                  </div>
                )}
              </FormGroup>

              <FormGroup className="mb-3">
                <div className="mt-10 ">
                  <label className="font-18">Image description:</label>
                </div>
                <InputGroup className="input-group-alternative">
                  <textarea
                    className="input form-control"
                    value={field.description}
                    placeholder="Image Description...."
                    {...register(`pic.${index}.description`, {
                      onChange: (e) => {
                        e.persist();
                        setFields((prevFields) => {
                          const updatedFields = [...prevFields];
                          updatedFields[index].description = e.target?.value;
                          return updatedFields;
                        });
                      },
                    })}
                  />
                </InputGroup>
                {errors?.pic?.[index]?.description && (
                  <div className="text-danger">
                    {errors?.pic?.[index]?.description?.message}
                  </div>
                )}
              </FormGroup>

              <FormGroup className="mb-3">
                <div className="mt-10 ">
                  <label className="font-18">Upload image:</label>
                </div>
                <InputGroup className="input-group-alternative">
                  <input
                    type="file"
                    required={imageRequired}
                    accept="image/png, image/gif, image/jpeg"
                    {...register(`pic.${index}.image`, {
                      onChange: (e) => {
                        e.persist();
                        setFields((prevFields) => {
                          const updatedFields = [...prevFields];
                          updatedFields[index].image = e.target?.files[0];
                          return updatedFields;
                        });
                      },
                    })}
                    // {...register(`pic.${index}.image`)}
                  />
                </InputGroup>
                {errors?.pic?.[index]?.image && (
                  <div className="text-danger">
                    {errors?.pic?.[index]?.image?.message}
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
        ))}

        <button
          className="btn btn-sm btn-primary ml-10 float-right"
          type="button"
          onClick={() => addField()}
        >
          + Add
        </button>
      </div>
    </div>
  );
});

import * as types from "./../types/coupon";

export const getCoupons = (token) => {
  return {
    type: types.GET_COUPONS,
    token
  };
};

export const getCouponsSuccess = (coupons) => {
  return {
    type: types.GET_COUPONS_SUCCESS,
    coupons,
  };
};

export const deleteCoupons = (id, token) => {
  return {
    type: types.DELETE_COUPON,
    payload: { id, token },
  };
};

export const deleteCouponsSuccess = (id) => {
  return {
    type: types.DELETE_COUPON_SUCCESS,
    id,
  };
};

export const addCoupon = (coupon, token) => {
  return {
    type: types.ADD_COUPON,
    payload: { coupon, token },
  };
};

export const addCouponSuccess = (payload) => {
  return {
    type: types.ADD_COUPON_SUCCESS,
    payload,
  };
};

export const updateCoupon = (id, coupon, token) => {
  return {
    type: types.UPDATE_COUPON,
    payload: { id, coupon, token },
  };
};

export const updateCouponSuccess = (data) => {
  return {
    type: types.UPDATE_COUPON_SUCCESS,
    data,
  };
};

export const resetCoupon = () => ({
  type: types.RESET_COUPON,
});

export const couponFailed = () => ({
  type: types.COUPON_FAILED,
});

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { Flag } from "@material-ui/icons";
import { connect } from "react-redux";

import Paginator from "../Pagination";
import Header from "../../components/Headers/DefaultHeader.jsx";
import DialogModal from "../../components/Modal/DialogModal.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";
import placeholder from "../../assets/img//Placeholder.png";
import * as userActions from "./../../store/actions/userActions";
import * as Const from "./../../utilities";
import Loading from "../../utilities/loading";

class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      selectedMedia: null,
      note: null,
      flagDialog: false,
      deleteMedia: false,
    };
  }
  componentDidMount() {
    const { token } = this.props.auth;
    this.props.getMedia(token, { deleted: false });
  }

  handleDelete = (selectedMedia) => {
    this.setState({ selectedMedia, deleteMedia: true });
  };

  paginationHandler = (page) => {
    this.setState({ page });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.user?.loading !== prevState?.user?.loading) {
      return { loading_: nextProps?.user?.loading };
    }

    return null;
  }

  onConfirm = () => {
    const { selectedMedia, note } = this.state;
    const { token } = this.props.auth;
    this.props.addFlag(
      selectedMedia._id,
      { flag: !selectedMedia?.flag, note },
      token
    );
    this.setState({ flagDialog: false });
  };

  onConfirmDelete = () => {
    const { selectedMedia } = this.state;
    const { token } = this.props.auth;
    this.props.trashMedia(selectedMedia._id, { deleted: true }, token);
    this.setState({ deleteMedia: false });
  };

  handleAddFlag = (selectedMedia) => {
    const { note } = this.state;
    const { token } = this.props.auth;
    if (selectedMedia?.flag) {
      this.props.addFlag(
        selectedMedia._id,
        { flag: !selectedMedia?.flag, note },
        token
      );
    } else {
      this.setState({ flagDialog: true, selectedMedia });
    }
  };

  render() {
    const { page, perPage } = this.state;
    const { media } = this.props;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Media</h3>
                </CardHeader>
                <div className="position-relative">
                  <Loading
                    width={70}
                    height={70}
                    loading={this.props.loading_}
                    className="kb-loading-table"
                  />
                  <Table
                    className={`${
                      this.props.loading_ ? "kb-overlay" : ""
                    } "align-items-center table-flush"`}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">IMAGE</th>
                        <th scope="col">Owner</th>
                        <th scope="col">
                          Flag an <br /> inappropriate
                        </th>
                        <th>Note</th>
                        <th scope="col">FILE</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {media &&
                        media.slice(startIndex, endIndex).map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={
                                    data?.mediaPic &&
                                    data?.mediaPic != null &&
                                    data.mediaPic
                                      .split("/")
                                      .pop()
                                      .split(".")[1] != "webm"
                                      ? `${
                                          data.mediaPic &&
                                          Const.cloudinaryImage({
                                            url: data.mediaPic,
                                            width: 50,
                                            format: true,
                                          })
                                        }`
                                      : `${placeholder}`
                                  }
                                  alt=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              </td>
                              <td>
                                {data?.owner?.firstName} {data?.owner?.lastName}
                              </td>
                              <td align="center">
                                <Flag
                                  className={data?.flag && "text-danger"}
                                  onClick={() => this.handleAddFlag(data)}
                                />
                              </td>
                              <td style={{ minWidth: "200px" }}>
                                {data?.note}
                              </td>
                              <td>
                                {data.mediaPic &&
                                data.mediaPic.split("/").pop().split(".")[1] ===
                                  "webp"
                                  ? data.mediaPic &&
                                    data.mediaPic.split("uploads/")[1]
                                  : data.mediaPic &&
                                    data.mediaPic.split("/").pop()}
                              </td>
                              <td>
                                {data.mediaPic &&
                                data.mediaPic.split(".")[1] === "webp" ? (
                                  <a
                                    href={`${
                                      process.env.REACT_APP_API_ENDPOINT
                                    }/${
                                      data.mediaPic &&
                                      data.mediaPic.split("public/")[1]
                                    }`}
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                ) : (
                                  <a
                                    href={data.mediaPic && data.mediaPic}
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                )}{" "}
                                |{" "}
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => this.handleDelete(data)}
                                >
                                  Delete
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      onPageChange={this.paginationHandler}
                      total={media ? media?.length : 0}
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        {this.state.deleteMedia && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={() => this.setState({ deleteMedia: false })}
            onConfirm={this.onConfirmDelete}
          />
        )}
        {this.state.flagDialog && (
          <DialogModal
            onClose={() => this.setState({ flagDialog: false })}
            onConfirm={this.onConfirm}
            title={"Flag an inappropriate"}
          >
            <textarea
              className="form-control"
              onChange={(e) => this.setState({ note: e.target.value })}
            />
          </DialogModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    media: state.user.media,
    loading_: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMedia: (token, query) => dispatch(userActions.getMedia(token, query)),
    trashMedia: (id, payload, token) =>
      dispatch(userActions.trashMedia(id, payload, token)),
    addFlag: (id, payload, token) =>
      dispatch(userActions.addFlagToMedia(id, payload, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Media);

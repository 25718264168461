import React from "react";
import { formatDate, profilePlaceholder } from "../../../../utilities";
import StnImage from "../../../../components/UI/Image";

const Message = (props) => {
  const { name, profilePic, message, createdAt } = props ?? {};

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        <StnImage
          className="rounded-circle"
          src={profilePic}
          placeholder={profilePlaceholder}
          width={40}
          height={40}
          alt="User Profile"
        />
        <div className="detail-wrapper">
          <div className="detail">{name}</div>
          <div className="date">{formatDate(createdAt)}</div>
          <pre className="message pt-3 m-0">{message}</pre>
        </div>
      </div>
    </div>
  );
};

export default Message;

import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function PaginationComponent({ showPerPage, paginationHandler, total }) {
  const [counter, setCounter] = useState(1);
  const [numberOfButtons, setNumberOfButtons] = useState(
    Math.ceil(total / showPerPage)
  );
  // const buttons = new Array(numberOfButtons);

  const total_buttons = Math.ceil(total / showPerPage);

  var buttons = [], i = 0, len = 10;
  while (++i <= total_buttons) buttons.push("");


  useEffect(() => {
    const value = showPerPage * counter;
    const start = value - showPerPage;
    const end = value;
    paginationHandler(start, end);
  }, [counter]);

  const onCuttonClickHandler = (type) => {
    if (type === "prev") {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === "next") {
      if (numberOfButtons === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
  };
  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem>
        <PaginationLink previous onClick={() => onCuttonClickHandler("prev")} />
      </PaginationItem>
      {buttons.fill("").map((value, index) => {
        return (
          <PaginationItem
            key={index}
            active={index + 1 === counter ? true : false}
            onClick={() => setCounter(index + 1)}
          >
            <PaginationLink>{index + 1}</PaginationLink>
          </PaginationItem>
        );
      })}

      <PaginationItem>
        <PaginationLink next onClick={() => onCuttonClickHandler("next")} />
      </PaginationItem>
    </Pagination>
  );
}

export default PaginationComponent;

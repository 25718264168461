import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdCloudDownload } from "react-icons/io";
import { cloudinaryImage, saveFile } from "../../utilities";

const W9PreviewModal = ({ handleClose, w9 }) => {
  const onDownload = () => {
    saveFile(cloudinaryImage({ url: w9 }), "w9");
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Body>
        <div className="position-relative">
          <img className="w-100 border rounded" src={w9} alt="w9" />
          <IoMdCloudDownload
            onClick={onDownload}
            style={{
              fontSize: 20,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              height: 35,
              width: 35,
            }}
            className="position-absolute top-1 right-1 rounded-circle cursor-pointer p-1"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default W9PreviewModal;

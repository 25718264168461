import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";

import AddUserModal from "./UserModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import CustomPagination from "../Pagination/pagination";
import UserListWithRoles, { formatAddress } from "./UserListWithRoles";
import Header from "./../../components/Headers/DefaultHeader";
import SearchField from "./../../components/SearchFields";
import { Loader } from "../../components/Loader";
import * as userActions from "./../../store/actions/userActions";
import "../User/User.css";

function UserWithRoles(props) {
  const {
    auth,
    getUsers,
    users,
    activeVendor,
    updateUserRole,
    updateUserPassword,
    deleteUser,
    loading,
  } = props;
  const { token } = auth;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userData, setUserData] = useState(null);
  const [addUserModal, setAddUserModal] = useState("");
  const [searchText, setSearchText] = useState("");
  const [paginateQuery, setPaginateQuery] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    getUsers(token);
  }, [token]);

  const usersData = useMemo(() => {
    if (searchText) {
      const searchRegex = new RegExp(searchText, "i");
      return users.filter((user) => {
        return [
          user?.email,
          user?.firstName,
          user?.lastName,
          user?.role?.name,
          typeof user?.address === "string"
            ? user?.address
            : formatAddress(user?.address),
        ].some((field) => field && searchRegex.test(field));
      });
    } else {
      return users;
    }
  }, [searchText, users]);

  const onActiveVendor = (id) => {
    activeVendor(id, token);
  };

  const onChangeRole = (id, value) => {
    updateUserRole(id, value.value, token);
  };

  const onChangePassword = (id, password) => {
    updateUserPassword(id, password, token);
  };

  const onDelete = (data) => {
    setUserData(data);
    setShowConfirmation(true);
  };

  const onConfirmDelete = () => {
    deleteUser(userData?._id, token);
    setUserData(null);
    setShowConfirmation(false);
  };

  const handleAddUser = () => {
    setUserData(null);
    setAddUserModal(true);
  };

  const paginationHandler = (start, end) => {
    setPaginateQuery({
      start,
      end,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Users With Roles</h3>
              </CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="HeaderWrapper">
                  <SearchField onChange={(value) => setSearchText(value)} />
                </div>
                <Button
                  onClick={handleAddUser}
                  color="primary"
                  className="mr-4"
                >
                  Add user
                </Button>
              </div>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">EMAIL</th>
                    <th scope="col">USERNAME</th>
                    <th scope="col">Address</th>
                    <th scope="col">ROLE</th>
                    <th scope="col">Access</th>
                    <th scope="col"></th>
                    <th scope="col">ACTIONS</th>
                  </tr>
                </thead>
                <UserListWithRoles
                  users={usersData}
                  activeVendor={onActiveVendor}
                  onChangeRole={onChangeRole}
                  onChangePassword={onChangePassword}
                  onDelete={onDelete}
                  onEdit={(data) => {
                    setAddUserModal(true);
                    setUserData(data);
                  }}
                  start={paginateQuery.start}
                  end={paginateQuery.end}
                />
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <CustomPagination
                    showPerPage={10}
                    paginationHandler={paginationHandler}
                    total={usersData.length}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {addUserModal && (
        <AddUserModal
          userData={userData}
          onHide={() => setAddUserModal(false)}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          title="Are you sure you want to delete this user?"
          onClose={() => setShowConfirmation(false)}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    product: state.product,
    category: state.category,
    users: state.user.users,
    loading: state.user.loading,
    errorMsg: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (token) => dispatch(userActions.getAllUsers(token)),
    activeVendor: (id, token) => dispatch(userActions.activeVendor(id, token)),
    updateUserRole: (id, role, token) =>
      dispatch(userActions.updateUserRole(id, role, token)),
    updateUserPassword: (id, password, token) =>
      dispatch(userActions.updateUserPassword(id, password, token)),
    deleteUser: (id, token) =>
      dispatch(userActions.deleteUserPermanently(id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWithRoles);

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { connect } from "react-redux";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import * as categoryActions from "../store/actions/categoryActions";
import * as userActions from "../store/actions/userActions.js";

import routes from "../routes.js";
import CreateProduct from "../views/Product/CreateProduct.jsx";
import CreateCoupon from "../views/Coupon/CreateCoupon.js";
import CreateCategory from "../views/Category/CreateCategory";
import StoreSetting from "../views/Setting/StoreSetting";
import CustomerSupport from "../views/Setting/CustomerSupport";
import CommissionSetting from "../views/Setting/CommissionSetting";
import PoliciesSetting from "../views/Setting/Policies";
import PaymentSetting from "../views/Setting/PaymentSetting";
import CreateArticle from "../views/Article/CreateArticle.js";
import CreateVendor from "../views/StoreVendor/CreateVendor";
import OrderActivity from "../views/Order/Activity";

import ProtectedRoute from '../protected.route';
import ProductSetting from "../views/Setting/product";
import Notifications from "../components/Notifications";
import UserOrders from "../views/Order/UserOrders";

class Admin extends React.Component {
  componentDidMount() {
    this.props.unreadNotification(this.props?.auth?.token);
    // this.props.getCategories();
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    let adminRoutes = [];
    // routes.splice((routes.length - 2), 1);
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.component) {
        adminRoutes.push(<ProtectedRoute
          path={prop.layout + prop.path}
          component={prop.component}
        // key={key}
        />)
      } else {
        return null;
      }
      return adminRoutes;
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    let latestRoutes = routes.filter(route => route.name !== "Single Store Vendor");

    return (
      <>
        <Sidebar
          {...this.props}
          auth={this.props?.auth}
          routes={latestRoutes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
          notificationCount={this.props.notificationCount}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            auth={this.props?.auth}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            <ProtectedRoute path="/admin/orders/activity/:id" component={OrderActivity} />
            {this.getRoutes(routes)}

            <ProtectedRoute path="/admin/user/:id" component={UserOrders} />
            <ProtectedRoute path="/admin/add-product" component={CreateProduct} />
            <ProtectedRoute path="/admin/add-vendor" component={CreateVendor} />
            <ProtectedRoute path="/admin/add-article" component={CreateArticle} />
            <ProtectedRoute path="/admin/createcoupons" component={CreateCoupon} />
            <ProtectedRoute path="/admin/add-category" component={CreateCategory} />
            <ProtectedRoute path="/admin/notifications" component={Notifications} />
            <ProtectedRoute path="/admin/StoreSetting" component={StoreSetting} />
            <ProtectedRoute path="/admin/productsetting" component={ProductSetting} />
            <ProtectedRoute path="/admin/customersupport" component={CustomerSupport} />
            <ProtectedRoute path="/admin/commissionsetting" component={CommissionSetting} />
            <ProtectedRoute path="/admin/storepolicies" component={PoliciesSetting} />
            <ProtectedRoute path="/admin/PaymentSetting" component={PaymentSetting} />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    category: state.categories,
    notificationCount: state.user.notificationCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unreadNotification: (token) => dispatch(userActions.unreadNotification(token)),
    getCategories: () => dispatch(categoryActions.getCategories())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

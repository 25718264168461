import * as types from "../types/coupon";
const initialState = {
  coupons: [],
  loading: false,
  error: "",
};

const couponReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case types.GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: actions.coupons,
        loading: false,
      };
    case types.ADD_COUPON: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.ADD_COUPON_SUCCESS:
      return {
        ...state,
        coupons: [...state.coupons, actions.payload],
        loading: false,
      };
    case types.UPDATE_COUPON: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.DELETE_COUPON: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.DELETE_COUPON_SUCCESS: {
      const coupons = [...state.coupons];
      return {
        ...state,
        coupons: coupons.filter((e) => e._id !== actions.id),
        loading: false,
      };
    }
    case types.RESET_COUPON: {
      return initialState;
    }
    case types.COUPON_FAILED: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

export default couponReducers;

import * as types from "../types/payment";

export const getVendorPayment = (id) => {
  return {
    type: types.GET_VENDOR_PAYMENT,
    payload: { id },
  };
};

export const getVendorPaymentSuccess = (payload) => {
  return {
    type: types.GET_VENDOR_PAYMENT_SUCCESS,
    payload,
  };
};

export const getAdminPayment = () => {
  return {
    type: types.GET_ADMIN_PAYMENT,
  };
};

export const getAdminPaymentSuccess = (payload) => {
  return {
    type: types.GET_ADMIN_PAYMENT_SUCCESS,
    payload,
  };
};

export const vendorRefund = (payload) => {
  return {
    type: types.VENDORE_REFUND,
    payload,
  };
};
export const vendorRefundSuccess = (payload) => {
  return {
    type: types.VENDORE_REFUND_SUCCESS,
    payload,
  };
};

export const getVendorRefund = (payload) => {
  return {
    type: types.GET_VENDORE_REFUND,
    payload,
  };
};
export const getVendorRefundSuccess = (payload) => {
  return {
    type: types.GET_VENDORE_REFUND_SUCCESS,
    payload,
  };
};
export const adminRefund = (payload) => {
  return {
    type: types.ADMIN_REFUND,
    payload,
  };
};
export const adminRefundSuccess = (payload) => {
  return {
    type: types.ADMIN_REFUND_SUCCESS,
    payload,
  };
};

export const resetPayment = () => ({
  type: types.RESET_PAYMENT,
});

export const getRefund = (token) => ({
  type: types.GET_REFUND,
  token,
});

export const getRefundSuccess = (payload) => ({
  type: types.GET_REFUND_SUCCESS,
  payload,
});

import { convertObjectToParamUrl } from "../../utilities";
import { del, get, HeadersOrigin, patch, post } from "./../lib/Api";

export async function categories(query, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "x-api-key": process.env.REACT_APP_X_API_KEY,
    ...HeadersOrigin,
  };
  const params = convertObjectToParamUrl(query);
  return get(`/category${params ? `/${params}` : ""}`, headers);
}

export async function deleteCategory(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/category/${id}`, headers);
}

export async function addCategory(category, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/category", category, headers);
}

export async function updateCategory(id, category, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/category/${id}`, category, headers);
}

export async function updateCategoryPriority(categories, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post(`/category/updatecategory`, { categories }, headers);
}

export async function hideCategory(id, category, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/category/hideData/${id}`, category, headers);
}

export async function trashCategory(id, payload, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post(`/category/trash/${id}`, payload, headers);
}

import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginator = ({ page, perPage, total, onPageChange }) => {
  const totalPages = Math.ceil(total / perPage);
  const maxVisiblePages = 5;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      handlePageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      handlePageChange(page + 1);
    }
  };

  const getPageButtons = () => {
    const buttons = [];
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <PaginationItem
            key={i}
            active={i === page}
            onClick={() => handlePageChange(i)}
          >
            <PaginationLink>{i}</PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      let start = Math.max(
        1,
        Math.min(
          page - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 2
        )
      );

      if (start > 2) {
        buttons.push(
          <PaginationItem
            key={1}
            active={1 === page}
            onClick={() => handlePageChange(1)}
          >
            <PaginationLink>1</PaginationLink>
          </PaginationItem>
        );
        if (start > 3) {
          buttons.push(
            <PaginationItem key="ellipsis-prev" disabled>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          );
        }
      }

      const end = Math.min(start + maxVisiblePages - 2, totalPages);

      for (let i = start; i <= end; i++) {
        buttons.push(
          <PaginationItem
            key={i}
            active={i === page}
            onClick={() => handlePageChange(i)}
          >
            <PaginationLink>{i}</PaginationLink>
          </PaginationItem>
        );
      }

      if (end < totalPages) {
        if (end < totalPages - 1) {
          buttons.push(
            <PaginationItem key="ellipsis-next" disabled>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          );
        }
        buttons.push(
          <PaginationItem
            key={totalPages}
            active={totalPages === page}
            onClick={() => handlePageChange(totalPages)}
          >
            <PaginationLink>{totalPages}</PaginationLink>
          </PaginationItem>
        );
      }
    }
    return buttons;
  };

  return (
    <Pagination className="pagination justify-content-end mb-0">
      <PaginationItem>
        <PaginationLink previous onClick={handlePrevPage} />
      </PaginationItem>
      {getPageButtons()}
      <PaginationItem>
        <PaginationLink next onClick={handleNextPage} />
      </PaginationItem>
    </Pagination>
  );
};

export default Paginator;

import * as types from './../types/auth';
import { rootReducers } from './index';

const initialState = {
	isAuthenticated: false,
	user: {},
	token: '',
	role: '',
	error: '',
	loading: false,
};

const authReducers = (state = initialState, action) => {
	switch (action.type) {
		case types.LOGIN_SUCCESS:
			const { userId } = action.payload.user;
			const { token } = action.payload;
			const { role, firstName, lastName, email, accesses } = action.payload.user;
			return {
				...state,
				isAuthenticated: true,
				user: userId,
				token: token,
				role: role,
				name: firstName + ' ' + lastName,
				email,
				loading: false,
				accesses: accesses ?? []
			};
		case types.LOGIN_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case types.LOGIN:
			return {
				...state,
				loading: false,
			};
		case types.LOGOUT_USER: {
			// state = undefined;
			// return rootReducers(state, action);
			return {
				state: undefined,
				loading: false,
				// isAuthenticated: false,
				// user: {},
				// token: "",
				// role: ""
			};
		}
		case types.RESET_AUTH: {
			return initialState;
		}
		default:
			return state;
	}
};

export default authReducers;

import React ,{useState,useEffect}from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import {useSelector} from "react-redux";
import "./ManageVendor.css"
const StoreHoursAndVacations = () =>{


    return(
        <Card className="mt-0 mb-3">
            {/*<h1>{JSON.stringify(vendor)}</h1>*/}
            <CardBody>
                <form className="form" action="" >
                                     {/*Store Hours Setting section starts here*/}

                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>Store Hours Setting</h1>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label mr-2" htmlFor="exampleCheck1">Enable Store Hours</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label" htmlFor="exampleCheck1">Disable Purchase During OFF Time</label>
                        <input type="checkbox" className="form-check-input ml-md-2" id="exampleCheck1"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="bannerType" className="font-weight-normal pl-4">
                            Set Day OFF
                        </label>
                        <select
                            name="bannerType"
                            required
                            className={"form-control w-50"}
                        >
                            <option value="staticImage">Select Day</option>
                            <option value="monday">Monday</option>
                            <option value="tuesday">Tuesday</option>
                            <option value="wednesday">Wednesday</option>
                            <option value="thursday">Thursday</option>
                            <option value="friday">Friday</option>
                            <option value="saturday">Saturday</option>
                            <option value="sunday">Sunday</option>
                        </select>
                    </div>
                    {/*Daily Basis Opening & Closing Hours section starts here*/}

                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>Store Hours Setting</h1>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Monday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Tuesday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Wednesday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Thursday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Friday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Saturday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                    <h3 className="mt-4 font-weight-bold text-gray">Sunday Time Slots</h3>
                    <div className="form-group customized-view-timer border px-md-5 py-md-3">
                        <label htmlFor="time" className="font-weight-normal text-gray">Opening</label>
                        <input type="time" className="form-control"/>
                        <label htmlFor="time" className="font-weight-normal text-gray">Closing</label>
                        <input type="time" className="ml-0 form-control"/>
                    </div>
                </form>
                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>
            </CardBody>
        </Card>
    )
}

export default StoreHoursAndVacations

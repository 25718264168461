import React from "react";
import Modal from "react-modal";
import { FaEye, FaEdit, FaTrash, FaCheck, FaWindowClose } from "react-icons/fa";
import { Row, Col, Container, Table } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Spinner } from "reactstrap";

import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
  },
};
const PopUpModal = (props) => {
  const items = props.image;
  return (
    <Modal
      isOpen={props.modalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={props.closeModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="containerModal">
        <Container>
          {items === "orderItems" && orderItems(props)}
          {items === "refund" && RefundForm(props)}
          {items !== "orderItems" &&
            items !== "refund" &&
            props.product &&
            normalView(props)}
        </Container>
      </div>
    </Modal>
  );
};

const normalView = (props) => {
  return (
    <Row>
      <Col sm="5">
        <div className="images">
          <img className="image" src={`${props.product?.productPic?.[0]?.url}`} alt="" />
        </div>
      </Col>
      <Col sm="7">
        <div className="product">
          <p>
            <em>
              {props.product && props.product.sku
                ? props.product.sku
                : "No keyword"}
            </em>{" "}
            &nbsp; &nbsp;
            {props.product.isPublish ? (
              <span
                className="badge badge-success"
                style={{ fontSize: "14px" }}
              >
                Published
              </span>
            ) : (
              <span
                className="badge badge-warning"
                style={{ fontSize: "14px" }}
              >
                Pending
              </span>
            )}
          </p>
          <div dangerouslySetInnerHTML={{ __html: props.product.name }} />
          <h2>
            <span
              style={{
                textDecoration: "line-through",
                color: "#fb6340",
              }}
            >
              ${props.product.price}
              <br />
            </span>
            <span
              style={{
                color: "Green",
              }}
            >
              ${props.product.finalPrice}
            </span>
            &nbsp; &nbsp; <FaEye size={"17px"} />
            &nbsp;{props.product.views}{" "}
            {/* ${props.product.price} &nbsp; &nbsp; <FaEye size={"17px"} />
            &nbsp;{props.product.views}{" "} */}
          </h2>
          {props.product.stock > 0 ? (
            <span className="inStock">
              <FaCheck />
              {props.product.stock} in stock
            </span>
          ) : (
            <span className="outStock">
              <FaWindowClose />
              {props.product.stock} in stock
            </span>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: props.product.description }}
          />
          <div className="buttons">
            <button
              onClick={() => props.handleEdit(props.product._id)}
              className="btn-sm btn-info editModal"
            >
              Edit <FaEdit />
            </button>
            <button
              onClick={() => props.handleDelete(props.product)}
              className="btn-sm btn-danger deleteModal"
            >
              Delete <FaTrash />
              {/* <span>♥Delete</span> */}
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
const orderItems = (orderItems) => {
  return (
    <Row>
      <Table>
        <thead className="thead-light">
          <tr>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              NAME
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              QUANTITY
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              IN STOCK
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              $ PRICE
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              $ TOTAL
            </th>
          </tr>
        </thead>
        <tbody style={{ fontWeight: "bolder" }}>
          {orderItems.product &&
            orderItems.product.map((item) => {
              return (
                <tr>
                  <td>{item.productId.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.productId.stock}</td>

                  <td>
                    <span
                      style={{
                        color: "#black",
                        fontSize: "15px",
                      }}
                    >
                      <b>
                        {" "}
                        ${Math.round(item.totalPrice / item.quantity)} / piece
                      </b>{" "}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color: "#black",
                        fontSize: "15px",
                      }}
                    >
                      <b> ${item.totalPrice}</b> <br />
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Row>
  );
};

const RefundForm = (props) => {

  return (
    <Form>
      {props.loading && <Spinner style={{ width: "3rem", height: "3rem" }} />}
      <FormGroup>
        <Label for="exampleEmail">REASON</Label>
        <Input
          type="text"
          name="reason"
          placeholder="Reason to refund"
          value={props.refund.reason}
          onChange={props.handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="exampleText">AMOUNT</Label>
        <Input
          type="number"
          name="amount"
          max={props.order?.amount}
          maxLength={props.order?.amount}
          placeholder="Refund amount"
          value={props.refund.amount}
          onChange={props.handleInputChange}
        />
      </FormGroup>
      <Button style={{ color: "red" }} onClick={props.handleRefund}>
        {`Refund $${props.refund.amount}`}
      </Button>
    </Form>
  );
};

export default PopUpModal;

import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./../types/order";
import * as service from "./../services/order";
import * as actions from "./../actions/orderActions";
import swal from "sweetalert";
import { errorResponse } from "./auth";
import { HeadersOrigin, get, patch, post } from "../lib/Api";
import { OrderStatus } from "../../utilities";

function* getOrder({ token }) {
  try {
    const result = yield call(service.orders, token);
    yield put(actions.getOrderSuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}
function* getMyOrder(action) {
  const { user } = action.payload;
  try {
    const result = yield call(service.myOrders, user);
    if (result.status === 200) {
      yield put(actions.getOrderSuccess(result.data.message));
    }
  } catch (error) {
    errorResponse(error);
  }
}

function* getOrderItemsSaga(action) {
  const { orderId, isVendor } = action.payload;
  try {
    const result = yield call(service.orderItmes, orderId, isVendor);
    if (result.data.success === true) {
      yield put(actions.getOrderItemsSuccess(result.data.message));
    }
  } catch (error) {
    errorResponse(error);
  }
}

function* deleteOrder(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.deleteOrder, id, token);
    if (result.status === 200) {
      yield put(actions.deleteOrderSuccess(id));
    }
  } catch (error) {
    errorResponse(error);
  }
}

function* getSingleOrder({ id, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const response = yield call(() => get(`/order/singleOrder/${id}`, headers));
    yield put(actions.getSingleOrderSuccess(response.data));
  } catch (err) {
    errorResponse(err);
  }
}

function* orderRefund({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => post("/order/refund", payload, headers));
    yield put(actions.orderRefundSuccess(payload));
    swal({
      icon: "success",
      title: "Refunded successfully",
      text: result?.message,
    });
  } catch (error) {
    errorResponse(error);
  }
}

function* userOrderRefund({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => post("/order/refund", payload, headers));
    yield put(actions.userOrderRefundSuccess(payload));
    swal({
      icon: "success",
      title: "Refunded successfully",
      text: result?.message,
    });
  } catch (error) {
    errorResponse(error);
  }
}

function* vendorOrders({ vendorId, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() =>
      get(`/order/vendorOrders/${vendorId}`, headers)
    );
    yield put(actions.getVendorOrdersSuccess(result?.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* userOrders({ userId, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => get(`/order/getorders/${userId}`, headers));
    yield put(actions.getUserOrdersSuccess(result?.data ?? []));
  } catch (error) {
    errorResponse(error);
  }
}

function* cancelOrder({ orderItemId, token, resolve, reject }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    yield call(() =>
      patch(`/order/${orderItemId}`, { status: OrderStatus.CANCELLED }, headers)
    );
    yield put(actions.cancelTheOrderSuccess({ orderItemId }));
    resolve(orderItemId);
  } catch (error) {
    errorResponse(error);
    reject(error);
  }
}

export default function* orderWatcher() {
  yield takeLatest(types.GET_ORDER, getOrder);
  yield takeLatest(types.DELETE_ORDER, deleteOrder);
  yield takeLatest(types.GET_MY_ORDER, getMyOrder);
  yield takeLatest(types.GET_ORDER_ITEMS, getOrderItemsSaga);
  yield takeLatest(types.GET_SINGLE_ORDER, getSingleOrder);
  yield takeLatest(types.ORDER_REFUND, orderRefund);
  yield takeLatest(types.USER_ORDER_REFUND, userOrderRefund);
  yield takeLatest(types.GET_VENDOR_ORDERS, vendorOrders);
  yield takeLatest(types.GET_USER_ORDERS, userOrders);
  yield takeLatest(types.CANCEL_THE_ORDER, cancelOrder);
}

import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Close } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { number, object, string } from "yup";
import TextareaField from "../UI/TextareaField";

const schema = object().shape({
  adminReason: string().required("Admin reason is required"),
  customerNote: string().required("Note for customer is required"),
  amount: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Amount is required"),
});

function RefundModal({ onClose, onConfirm }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      className="confirmation-modal"
      size="md"
      show={true}
      onHide={onClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header style={{ paddingBottom: 0 }}>
        <h2 className="text-dark">Refund</h2>
        <Close onClick={onClose} className="cursor-pointer" />
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "100%", paddingTop: 5 }}>
        <Form onSubmit={handleSubmit(onConfirm)}>
          <div className="mb-3">
            <TextareaField
              name="adminReason"
              register={register}
              label="Admin - Reason for Cancellation"
            />
            {errors.adminReason && (
              <span className="text-danger">{errors.adminReason.message}</span>
            )}
          </div>
          <div className="mb-3">
            <TextareaField
              name="customerNote"
              register={register}
              label="Message Cancellation Note to Customer"
            />
            {errors.customerNote && (
              <span className="text-danger">{errors.customerNote.message}</span>
            )}
          </div>
          <div className="mb-3">
            <TextField
              name="amount"
              label="Amount"
              variant="outlined"
              fullWidth
              {...register("amount")}
            />
            {errors.amount && (
              <span className="text-danger">{errors.amount.message}</span>
            )}
          </div>
          <div className="mt-4 btn-groups">
            <Button type="submit" className="yes">
              Yes
            </Button>
            <Button
              className="no"
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              No
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default RefundModal;

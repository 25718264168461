import { del, get, HeadersOrigin, patch, post } from "./../lib/Api";

export async function addCommisionSettings(settings, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/commission_settings", settings, { headers });
}

export async function getCommisionSettings(token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return get("/commission_settings", headers);
}

export async function getAllDeliveryOptionsWithSection() {
  const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
  return get("/deliveryOption/getAllDeliveryOptionsWithSection", headers);
}

export async function addOption(option, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/deliveryOption", { ...option }, headers);
}

export async function editOption(option, id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/deliveryOption/${id}`, { ...option }, headers);
}

export async function deleteOption(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/deliveryOption/${id}`, headers);
}

export async function addSection(section, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/deliveryOptionType", { ...section }, headers);
}

export async function deleteSection(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/deliveryOptionType/${id}`, headers);
}

import Joi from "joi-browser";

export const forgotPasswordSchema = {
  email: Joi.string().email().required().label("Email"),
};

export const resetPasswordSchema = {
  password: Joi.string().required().label("Password"),
  confirmPassword: Joi.string().required().label("Confirm Password"),
};

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Card,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import { string, array, object, mixed } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import DefaultHeader from "../../components/Headers/DefaultHeader.jsx";
import AddVideo from "../Article/AddVideo";
import AddImage from "../Article/AddImage";
import "../Article/Articles.scss";
import * as articleActions from "./../../store/actions/articleActions";
import * as categoryActions from "./../../store/actions/categoryActions";
import { authSelector } from "./../../store/reducers";
import Loading from "../../utilities/loading.js";
import ArticleContent from "./ArticleContent.jsx";

const AddArticle = (props) => {
  const { categories, getCategories, location, articles, auth } = props;
  const articleData = articles.find((item) => item?._id === location.state);
  const dispatch = useDispatch();
  const defaultField = { title: "", description: "", image: null, _id: "" };
  const defaultFieldVideoURL = {
    title: "",
    description: "",
    url: "",
    _id: "",
  };
  const [article, setArticle] = useState({
    name: articleData?.name ?? "",
    metaTags: articleData?.metaTags ?? "",
    description: articleData?.description ?? "",
    cover: articleData?.cover ?? "",
    category: articleData?.category?._id ?? "",
    pic: articleData?.pic ?? [],
    video: articleData?.video ?? [],
  });

  const validationSchema = object().shape({
    name: string().required("Name is required"),
    metaTags: string().required("Meta Tags is required"),
    description: string().required("Description is required"),
    cover: articleData?.name
      ? mixed().optional()
      : mixed().required("Cover is required"),
    category: string().required("Category is required"),
    pic: articleData?.name
      ? array().of(
          object().shape({
            title: string().required("Title is required"),
            _id: string().required("_id is required"),
            description: string().required("Description is required"),
            image: mixed().required("Image is required"),
          })
        )
      : array().of(
          object().shape({
            title: string().required("Title is required"),
            description: string().required("Description is required"),
            image: mixed().required("Image is required"),
          })
        ),
    videoUrls: array().of(
      object().shape({
        title: string().required("Title is required"),
        description: string().required("Description is required"),
        url: mixed().required("Video Url is required"),
      })
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const [fields, setFields] = useState(
    article.pic?.length > 0 ? article.pic : []
  );
  const [videoUrls, setVideoUrls] = useState(
    article?.video?.length > 0 ? article.video : []
  );

  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);

  const { errors, isValid } = formState;
  const { append, remove } = useFieldArray({
    control,
    name: "fields",
  });
  const { append: appendVideoURL, remove: removeVideoURL } = useFieldArray({
    control,
    name: "videoUrls",
  });

  useEffect(() => {
    auth?.token && getCategories({ deleted: false }, auth?.token);
  }, [auth?.token]);

  function onSubmit(formFields) {
    const { name, metaTags, description, cover, category, pic, videoUrls } =
      formFields;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("metaTags", metaTags);
    formData.append("description", description);
    formData.append("cover", cover[0]);
    formData.append("category", category);
    formData.append("owner", auth?.user);
    if (articleData?.name && pic && pic.length) {
      for (const image of pic) {
        const {
          _id,
          title,
          description,
          image: [file],
        } = image;
        formData.append("titles[]", title);
        formData.append("descriptions[]", description);
        formData.append("_ids[]", _id);

        if (file) {
          formData.append("imageName[]", file.name);
          formData.append("pic", file);
        } else {
          formData.append("imageName[]", "");
        }
      }
    } else if (pic && pic.length) {
      for (const image of pic) {
        const {
          title,
          description,
          image: [file],
        } = image;
        formData.append("titles[]", title);
        formData.append("descriptions[]", description);
        formData.append("pic", file);
      }
    }
    if (videoUrls && videoUrls.length) {
      for (const item of videoUrls) {
        const { title, description, url } = item;
        formData.append("videoTitles[]", title);
        formData.append("videoDescriptions[]", description);
        formData.append("videoUrl[]", url);
      }
    }

    if (articleData?.name) {
      dispatch(
        articleActions.updateArticle(articleData._id, formData, auth?.token)
      );
    } else {
      dispatch(articleActions.addArticle(formData, auth?.token));
    }

    props.history.push(`/dashboard/articles`);
    reset();
  }

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0 position-relative">
              <Card.Body className="px-lg-5 py-lg-5">
                <div className="text-center text-lg font-weight-bold mb-4">
                  Add Article
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={false}
                  className="kb-loading"
                />

                <form
                  role="form"
                  encType="multipart/file-data"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Name:</label>
                    </div>
                    <InputGroup className="input-group-alternative">
                      <input
                        name="name"
                        type="text"
                        value={article.name}
                        placeholder="Enter Title Here..."
                        className="input form-control"
                        {...register("name", {
                          onChange: (e) => {
                            setArticle({ ...article, name: e.target.value });
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.name?.message && (
                      <div className="text-danger">{errors.name?.message}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Meta Tags:</label>
                    </div>
                    <InputGroup className="input-group-alternative">
                      <input
                        name="metaTags"
                        type="text"
                        value={article.metaTags}
                        placeholder="Enter Meta Tags Here..."
                        className="input form-control"
                        {...register("metaTags", {
                          onChange: (e) => {
                            setArticle({
                              ...article,
                              metaTags: e.target.value,
                            });
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.metaTags?.message && (
                      <div className="text-danger">
                        {errors.metaTags?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Description:</label>
                    </div>
                    <InputGroup className="input-group-alternative">
                      <textarea
                        className="input form-control"
                        value={article.description}
                        placeholder="Article Description and content..."
                        rows={4}
                        width="100%"
                        cols={96}
                        {...register("description", {
                          onChange: (e) => {
                            setArticle({
                              ...article,
                              description: e.target.value,
                            });
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.description?.message && (
                      <div className="text-danger">
                        {errors.description?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Cover image:</label>
                    </div>
                    <InputGroup className="input-group-alternative">
                      <input
                        type="file"
                        required={articleData?.name ? false : true}
                        accept="image/png, image/gif, image/jpeg"
                        {...register("cover", {
                          onChange: (e) => {
                            setArticle({
                              ...article,
                              cover: e.target.files[0],
                            });
                          },
                        })}
                      />
                    </InputGroup>
                    {errors?.cover && (
                      <div className="text-danger">
                        {errors?.cover?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Select Category:</label>
                    </div>
                    <InputGroup className="input-group-alternative">
                      <select
                        className="input form-control category"
                        value={article.category}
                        placeholder="--- Select cateogry ---"
                        width="100%"
                        {...register("category", {
                          onChange: (e) => {
                            setArticle({
                              ...article,
                              category: e.target.value,
                            });
                          },
                        })}
                      >
                        <option value="">--- Select cateogry ---</option>
                        {categories.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </InputGroup>
                    {errors.category?.message && (
                      <div className="text-danger">
                        {errors.category?.message}
                      </div>
                    )}
                  </FormGroup>

                  <AddVideo
                    register={register}
                    errors={errors}
                    defaultFieldVideoURL={defaultFieldVideoURL}
                    videoUrls={videoUrls}
                    setVideoUrls={setVideoUrls}
                    appendVideoURL={appendVideoURL}
                    removeVideoURL={removeVideoURL}
                    videoRequired={true}
                  />

                  <AddImage
                    register={register}
                    errors={errors}
                    fields={fields}
                    append={append}
                    remove={remove}
                    setFields={setFields}
                    defaultField={defaultField}
                    imageRequired={articleData?.name ? false : true}
                  />

                  <div className="save-cancel-wrapper">
                    <button
                      type="submit"
                      disabled={!isValid}
                      className="btn btn-success"
                    >
                      PUBLISH
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(e) => props.history.push(`/dashboard/articles`)}
                    >
                      <span>CANCEL</span>
                    </button>
                  </div>
                </form>
                <ArticleContent
                  auth={auth}
                  directContent={article}
                  videoUrls={videoUrls}
                  mediaUrls={fields}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    product: state.product,
    categories: state.category.categories,
    articles: state.article.articles,
    loading_: state.article.loading,
    errorMsg: state.article.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticle: () => dispatch(articleActions.getArticle()),
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    addArticle: (article, token) =>
      dispatch(articleActions.addArticle(article, token)),
    updateArticle: (id, article, token) =>
      dispatch(articleActions.updateArticle(id, article, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);

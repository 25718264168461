import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import * as settingActions from "../../../store/actions/settingActions";
import "./style.scss";
import OptionSection from "./optionSection";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ProductSetting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingActions.getAllDeliveryOptionsWithSection());
  }, []);

  const { deliveryOptions, loading } = useSelector((state) => {
    return state["setting"];
  });

  const auth = useSelector((state) => {
    return state["auth"];
  });

  let [addSection, setAddSection] = useState(true);
  let [newSectionName, setNewSectionName] = useState({
    name: "",
  });

  let addNewSectionDiv = (e) => {
    e.preventDefault();
    setAddSection((prevState) => !prevState);
  };
  let changeNewSectionName = (e) => {
    setNewSectionName({
      ...newSectionName,
      [e.target.name]: e.target.value,
    });
  };

  let addNewSection = (e) => {
    e.preventDefault();
    dispatch(settingActions.addNewSection(newSectionName, auth?.token));
    setAddSection((prevState) => !prevState);
  };

  const onDragEnd = (result) => {
    if (loading == true) {
      return;
    } else {
      if (!result.destination) {
        return;
      }

      if (result.type === "option") {
        const options = reorder(
          deliveryOptions,
          result.source.index,
          result.destination.index
        );

        dispatch(settingActions.getAllDeliveryOptionsWithSection(options));
        let listOfOptionsId = [];
        for (const option of options) {
          listOfOptionsId.push(option?.section._id);
        }

        dispatch(settingActions.updatePriority(listOfOptionsId, auth?.token));
      } else if (result.type === "subOption") {
        const subOptions = deliveryOptions.reduce((acc, item) => {
          acc[item?.section?._id] = item.options;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const sourceSubOptions = subOptions[sourceParentId];

        let options = [...deliveryOptions];

        if (sourceParentId === destParentId) {
          const reorderedSubOptions = reorder(
            sourceSubOptions,
            result.source.index,
            result.destination.index
          );

          options = options.map((option) => {
            if (option?.section?._id === sourceParentId) {
              option.options = reorderedSubOptions;
            }
            return option;
          });

          dispatch(settingActions.getAllDeliveryOptionsWithSection(options));
          let listOfOptionsId = [];
          for (const option of reorderedSubOptions) {
            listOfOptionsId.push(option?._id);
          }

          dispatch(
            settingActions.updatePriorityForOption(
              sourceParentId,
              listOfOptionsId,
              auth?.token
            )
          );
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="option" type="option">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="ProductSetting-wrapper"
          >
            {loading ? (
              <div>loading....</div>
            ) : (
              <>
                {deliveryOptions.map((item, indx) => {
                  return (
                    <Draggable
                      isDragDisabled={loading}
                      draggableId={item?.section?._id}
                      key={item?.section?._id}
                      index={indx}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OptionSection
                            key={indx}
                            options={deliveryOptions}
                            optionsType={item.options}
                            section={item?.section}
                            token={auth?.token}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <div className="add-section-wrapper">
                  {addSection ? (
                    <>
                      <div className="add-section" onClick={addNewSectionDiv}>
                        ADD SECTION
                      </div>
                      <div className="border-top-custom mt-3" />
                    </>
                  ) : (
                    <div className="d-flex flex-column">
                      <div className="add-section" onClick={addNewSectionDiv}>
                        ADD SECTION
                      </div>
                      <div className="mt-2 form-group d-flex flex-row">
                        <input
                          value={newSectionName.name}
                          name="name"
                          onChange={changeNewSectionName}
                          type="text"
                          placeholder="Section Name"
                          className="form-control text-center"
                        />
                        <button
                          onClick={addNewSection}
                          type="button"
                          className="btn btn-primary"
                        >
                          ADD SECTION
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductSetting;

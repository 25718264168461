import { boolean, mixed, number, object, string } from "yup";

export const productValidationSchema = object().shape({
  length: string().required("Length is required"),
  lengthUnit: mixed().required("Length Unit is required"),
  width: string().required("Width is required"),
  widthUnit: mixed().required("Width Unit is required"),
  major_color: mixed().required("Major Color is required"),
  minor_color: mixed().required("Minor Color is required"),
  category: mixed().required("Category is required"),
  thickness: mixed().required("Thickness is required"),
  surfaces: mixed().required("Surface is required"),
  countryOfOrigin: mixed().required("Country Of Origin is required"),
  price: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Price is required"),
  finalPrice: number().min(0),
  stock: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Stock is required"),
  sku: string().optional(),
  includeFreeDelivery: boolean().optional(),
  freeDeliveryDistance: number().when(
    "includeFreeDelivery",
    (includeFreeDelivery, schema) => {
      return includeFreeDelivery
        ? schema
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(
              "Free delivery distance is required if free delivery is included"
            )
            .min(0, "Free delivery distance cannot be negative")
            .max(100, "No more then 100 distance")
        : schema.optional();
    }
  ),
  deliveryDistance: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, "Delivery distance cannot be negative")
    .max(1000, "No more then 1000 distance")
    .optional(),
  isPickupOnly: boolean().optional(),
});

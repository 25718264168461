import React, { Component } from "react";
import { LoaderHOC } from "../../components/Hoc/LoaderHoc";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Chip } from "@material-ui/core";
import { accessFeatures } from "../../utilities";

export const formatAddress = (object) => {
  const addressParts = [
    object?.streetAddress,
    object?.city,
    object?.state,
    object?.postCode,
    object?.country,
  ];
  return addressParts
    .filter((part) => part !== undefined && part !== "")
    .join(", ");
};

class UserListWithRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      userId: "",
    };
  }

  onChangePassword = (password, userId) => {
    this.setState({ password, userId });
  };

  onConfirmPassword = () => {
    const { userId, password } = this.state;
    this.props.onChangePassword(userId, password);
    this.setState({ password: null, userId: null });
  };

  render() {
    const { users, activeVendor, onDelete, onEdit, start, end } = this.props;

    return (
      <tbody>
        {users.slice(start, end).map((user) => {
          return (
            <tr key={user._id}>
              <td>{user.email}</td>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td
                style={{
                  minWidth: "200px",
                  maxWidth: "300px",
                  whiteSpace: "normal",
                }}
              >
                {typeof user?.address
                  ? user?.address
                  : formatAddress(user?.address)}
              </td>
              <td style={{ minWidth: "200px" }}>
                <Chip
                  className="text-capitalize"
                  label={user?.role?.name}
                  variant="outlined"
                />
              </td>
              <td style={{ minWidth: "200px" }}>
                <div className="d-flex flex-wrap">
                  {accessFeatures.map(
                    (access, i) =>
                      user?.accesses?.includes(access?.value) && (
                        <React.Fragment key={access.label}>
                          <span className="text-capitalize">
                            {access.label},
                          </span>
                        </React.Fragment>
                      )
                  )}
                </div>
              </td>
              <td>
                <Button
                  className={`btn btn-sm ${
                    user.activate ? "btn-danger" : "btn-success"
                  }`}
                  onClick={() => activeVendor(user._id)}
                >
                  {user.activate ? "Disable" : "Active"}
                </Button>
              </td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light kb-topmenu"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      className="kb-firstCapital"
                      onClick={() => onDelete(user._id)}
                    >
                      Delete
                    </DropdownItem>
                    <DropdownItem
                      className="kb-firstCapital"
                      onClick={() => onEdit(user)}
                    >
                      Edit
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default LoaderHOC("users", 5)(UserListWithRoles);

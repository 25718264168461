import React from "react";
import "./loader.css";
import ReactLoader from "react-loader-spinner";

export const Loader = ({ isFullPage = true }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        isFullPage ? "cover" : "cover-inner"
      }`}
    >
      <ReactLoader
        type="Oval"
        color="#00BFFF"
        height={50}
        width={50}
        timeout={30000}
      />
    </div>
  );
};

export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";

export const RESET_COUPON = "RESET_COUPON";

export const COUPON_FAILED = "COUPON_FAILED";
import React ,{useState,useEffect}from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import {useSelector} from "react-redux";
import img from '../../../assets/img/Placeholder.png'
import { Editor } from "@tinymce/tinymce-react";
import "./ManageVendor.css"
const StoreSetting = () =>{
    const vendorDetails = useSelector(state => {
        return state["user"].vendor;
    })

    const [vendor,setVendor] = useState({
        storeName:'',
        email:'',
        contact:'',
        description:'',
    })
    let handleChange = (content, editor)=>{
        setVendor({...vendor,description: content})
    }
    const [address, setAddress] = useState({
        country:'',
        state:''
    });
    let selectCountry =  (val) =>{
        setAddress({ ...address,country: val });
    }
    let selectRegion  =  (val) =>{
        setAddress({ ...address,state: val });
    }

    useEffect(()=>{
        setVendor({
            ...vendor,
            storeName: vendorDetails?.vendor?.storeName,
            email: vendorDetails?.vendor?.email,
            contact: vendorDetails?.vendor?.contact
        })
    },[vendorDetails])
    let updateInput = (e) =>{
        setVendor({...vendor,[e.target.name] : e.target.value})
    }
    return(
        <Card className="mt-0 mb-3">
            {/*<h1>{JSON.stringify(vendor)}</h1>*/}
            <CardBody>
                <form className="form" action="" >
                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>General Setting</h1>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            Store Name<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="email" className="font-weight-normal">
                            E-mail<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.email} onChange={updateInput} name="email" type="email" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="contact" className="font-weight-normal">
                            Store Phone<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.contact} onChange={updateInput} name="email" type="email" className="form-control w-50"/>
                    </div>
                    <h1 className={"font-weight-bold text-primary my-3"}>Store Brand Setup</h1>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="file" className="font-weight-normal">
                           Store Logo<small className="text-danger">*</small>
                        </label>
                        <div className="img-container d-flex align-items-start justify-content-start w-50">
                            <img src={img} alt="" className="border" width="150px" style={{cursor:"pointer"}}/>
                        </div>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="bannerType" className="font-weight-normal">
                            Store Banner Type<small className="text-danger">*</small>
                        </label>
                        <select
                        name="bannerType"
                        value={""}
                        readOnly={true}
                        required
                        className={"form-control w-50"}
                        >
                            <option value="staticImage">Static Image</option>
                            <option value="slider">Slider</option>
                            <option value="video">video</option>
                        </select>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="file" className="font-weight-normal">
                            Banner<small className="text-danger">*</small>
                        </label>
                        <div className="img-container d-flex align-items-start justify-content-start w-50">
                            <img src={img} alt="" className="border" width="150px" style={{cursor:"pointer"}}/>
                        </div>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="file" className="font-weight-normal">
                            Mobile Banner<small className="text-danger">*</small>
                        </label>
                        <div className="img-container d-flex align-items-start justify-content-start w-50">
                            <img src={img} alt="" className="border" width="150px" style={{cursor:"pointer"}}/>
                        </div>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="bannerType" className="font-weight-normal">
                            Store List Banner Type<small className="text-danger">*</small>
                        </label>
                        <select
                            name="bannerType"
                            value={""}
                            readOnly={true}
                            required
                            className={"form-control w-50"}
                        >
                            <option value="staticImage">Static Image</option>
                            <option value="video">video</option>
                        </select>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="file" className="font-weight-normal">
                            Store List Banner<small className="text-danger">*</small>
                        </label>
                        <div className="img-container d-flex align-items-start justify-content-start w-50">
                            <img src={img} alt="" className="border" width="150px" style={{cursor:"pointer"}}/>
                        </div>
                    </div>
                    <div className="form-group">
                        {/*<Editor*/}
                        {/*    value={vendor.description}*/}
                        {/*    init={{*/}
                        {/*        height: 300,*/}
                        {/*        menubar: false*/}
                        {/*    }}*/}
                        {/*    onEditorChange={handleChange}*/}
                        {/*/>*/}
                    </div>
                    <div className="d-flex align-items-end justify-content-end mt-3">
                        <button className="btn btn-dark">
                            update
                        </button>
                    </div>
                    {/*//  store address starts here*/}
                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>Store Address</h1>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            Street<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            Street 2<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            City/Town<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            Postcode/Zip<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            Country<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            State/Country<small className="text-danger">*</small>
                        </label>
                        <input value={vendor?.storeName} onChange={updateInput} name="storeName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>Visibility Setup</h1>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="bannerType" className="font-weight-normal">
                            Store Name Position<small className="text-danger">*</small>
                        </label>
                        <select
                            name="bannerType"
                            value={""}
                            readOnly={true}
                            required
                            className={"form-control w-50"}
                        >
                            <option value="staticImage">On Bannner</option>
                            <option value="staticImage">At Header</option>
                        </select>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="storeName" className="font-weight-normal">
                            State/Country<small className="text-danger">*</small>
                        </label>
                        <input name="storeName" type="number" className="form-control w-50"/>
                    </div>


                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label mr-2" htmlFor="exampleCheck1">Hide Email from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label" htmlFor="exampleCheck1">Hide Phone from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label mr-2" htmlFor="exampleCheck1">Hide Address from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label" htmlFor="exampleCheck1">Hide Map from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label mr-2" htmlFor="exampleCheck1">Hide About from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                    <div className="form-group form-check flex-direction-custom">
                        <label className="form-check-label" htmlFor="exampleCheck1">Hide Policy from Store</label>
                        <input type="checkbox" className="form-check-input ml-1" id="exampleCheck1"/>
                    </div>
                </form>

                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>
            </CardBody>
        </Card>
    )
}
export default StoreSetting

import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Table, Container } from "reactstrap";
import { connect } from "react-redux";

import Header from "../../components/Headers/DefaultHeader";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import CustomPagination from "../Pagination/pagination";
import * as userActions from "./../../store/actions/userActions";
import placeholder from "../../assets/img//Placeholder.png";
import * as Const from "./../../utilities";
import Loading from "../../utilities/loading";
import MediaSkeleton from "../../components/Skeleton/Media";

const Media = (props) => {
  const { getMedia, media, deleteMedia, trashMedia, auth, loading } = props;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });
  const [selectedMedia, setSelectedMedia] = useState(null);

  const { start, end } = pagination;
  const skeletons = Array.from({ length: 6 });

  useEffect(() => {
    getMedia(auth?.token, { deleted: true });
  }, []);

  const onConfirmDelete = () => {
    deleteMedia(selectedMedia?._id, auth?.token);
    setDeleteDialog(false);
  };

  const onConfirmRestore = () => {
    trashMedia(selectedMedia?._id, { deleted: false }, auth?.token);
    setRestoreDialog(false);
  };

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedMedia(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedMedia(data);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Media</h3>
          </CardHeader>
          <div className="position-relative">
            <Loading
              width={70}
              height={70}
              loading={props.loading_}
              className="kb-loading-table"
            />
            <Table
              className={`${
                props.loading_ ? "kb-overlay" : ""
              } "align-items-center table-flush"`}
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">IMAGE</th>
                  <th scope="col">OWNER</th>
                  <th scope="col">FILE</th>
                  <th scope="col">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  skeletons.map(() => <MediaSkeleton />)
                ) : media?.length === 0 ? (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Record not found
                    </td>
                  </tr>
                ) : (
                  media &&
                  media.slice(start, end).map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={
                              data?.mediaPic &&
                              data?.mediaPic != null &&
                              data.mediaPic.split("/").pop().split(".")[1] !=
                                "webm"
                                ? `${data.mediaPic && data.mediaPic}`
                                : `${placeholder}`
                            }
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        </td>
                        <td>
                          {data?.owner?.firstName} {data?.owner?.lastName}
                        </td>
                        <td>
                          {data.mediaPic &&
                          data.mediaPic.split("/").pop().split(".")[1] ===
                            "webp"
                            ? data.mediaPic &&
                              data.mediaPic.split("uploads/")[1]
                            : data.mediaPic && data.mediaPic.split("/").pop()}
                        </td>
                        <td>
                          {data.mediaPic &&
                          data.mediaPic.split(".")[1] === "webp" ? (
                            <a
                              className="text-primary cursor-pointer"
                              href={`${process.env.REACT_APP_API_ENDPOINT}/${
                                data.mediaPic &&
                                data.mediaPic.split("public/")[1]
                              }`}
                              target="_blank"
                            >
                              View{" | "}
                            </a>
                          ) : (
                            <a
                              className="text-primary cursor-pointer"
                              href={data.mediaPic && data.mediaPic}
                              target="_blank"
                            >
                              View{" | "}
                            </a>
                          )}
                          <a
                            className="text-primary cursor-pointer"
                            onClick={() => handleActions(data, "restore")}
                          >
                            Restore{" | "}
                          </a>
                          <a
                            className="text-primary cursor-pointer"
                            onClick={() => handleActions(data, "delete")}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <CustomPagination
                showPerPage={10}
                paginationHandler={paginationHandler}
                total={media && media.length}
              />
            </nav>
          </CardFooter>
        </Card>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title={"Are you sure you want to delete?"}
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {restoreDialog && (
        <ConfirmationModal
          title={"Are you sure you want to restore?"}
          onClose={() => setRestoreDialog(false)}
          onConfirm={onConfirmRestore}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    media: state.user.media,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMedia: (token, query) => dispatch(userActions.getMedia(token, query)),
    deleteMedia: (id, token) => dispatch(userActions.deleteMedia(id, token)),
    trashMedia: (id, payload, token) =>
      dispatch(userActions.trashMedia(id, payload, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Media);

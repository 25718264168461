import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Button,
  Container,
} from "reactstrap";
import { connect } from "react-redux";

import Header from "../../components/Headers/DefaultHeader";
import SearchField from "../../components/SearchFields/index.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";
import CategorySkeleton from "../../components/Skeleton/Category.jsx";
import CustomPagination from "../Pagination/pagination.js";
import * as categoryActions from "../../store/actions/categoryActions.js";

const Categories = (props) => {
  const {
    auth,
    getCategories,
    categories: categoryData,
    trashCategory,
    deleteCategory,
    loading,
  } = props;

  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { start, end } = pagination;
  const skeletons = Array.from({ length: 6 });

  useEffect(() => {
    auth?.token && getCategories({ deleted: true }, auth?.token);
  }, [auth?.token]);

  useEffect(() => {
    setCategories(categoryData);
  }, [categoryData]);

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedCategory(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedCategory(data);
    }
  };

  const closeModal = () => {
    setDeleteDialog(false);
    setRestoreDialog(false);
  };

  const onConfirmRestore = () => {
    trashCategory(selectedCategory._id, { deleted: false }, auth?.token);
    closeModal();
  };

  const onConfirmDelete = () => {
    deleteCategory(selectedCategory._id, auth?.token);
    closeModal();
  };

  const onSearchChange = (value) => {
    if (value) {
      let searchText = value.toLowerCase();
      const filterCategories = categories.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.includes(searchText);
      });
      setCategories(filterCategories);
    } else {
      setCategories(categoryData);
    }
  };

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Categories</h3>
          </CardHeader>
          <div className="HeaderWrapper">
            <SearchField onChange={onSearchChange} />
          </div>
          <div className="position-relative">
            <div>
              <Table
                className={`${
                  loading ? "kb-overlay" : ""
                } "align-items-center table-flush"`}
                responsive
              >
                <thead className="thead-light TableData">
                  <tr>
                    <th scope="col">NAME</th>
                    <th scope="col">META TAGS</th>
                    <th scope="col">VISIBILITY</th>
                    <th scope="col" className="text-right">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    skeletons.map(() => <CategorySkeleton />)
                  ) : categories.length === 0 ? (
                    <tr>
                      <td colSpan={12} className="text-center">
                        Record not found
                      </td>
                    </tr>
                  ) : (
                    categories.slice(start, end).map((cate, i) => (
                      <tr>
                        <td>{cate.name}</td>
                        <td>{cate.meta_tags}</td>
                        <td>{cate.hide_Status ? "Hidden" : "Visible"}</td>
                        <td className="text-right">
                          <a
                            className="text-primary cursor-pointer"
                            onClick={() => handleActions(cate, "restore")}
                          >
                            Restore{" | "}
                          </a>
                          <a
                            className="text-primary cursor-pointer"
                            onClick={() => handleActions(cate, "delete")}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <CustomPagination
                showPerPage={10}
                paginationHandler={paginationHandler}
                total={categories.length}
              />
            </nav>
          </CardFooter>
        </Card>
      </Container>
      {restoreDialog && (
        <ConfirmationModal
          title="Are you sure you want to restore?"
          onClose={closeModal}
          onConfirm={onConfirmRestore}
        />
      )}
      {deleteDialog && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={closeModal}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    categories: state.category.categories,
    loading: state.category.loading,
    errorMsg: state.category.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    trashCategory: (id, payload, token) =>
      dispatch(categoryActions.trashCategory(id, payload, token)),
    deleteCategory: (id, token) =>
      dispatch(categoryActions.deleteCategory(id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);

import { date, mixed, number, object, string } from "yup";

export const couponSchema = object().shape({
  code: string()
    .min(5, "Code must be atleast 5 characters long")
    .required("Code is required"),
  targetUser: mixed().required("Target user is required"),
  discountType: mixed().required("Type is required"),
  discountValue: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required("Amount is required"),
  maxUsage: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  maxUsagePerUser: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required("Usage per user is required"),
  validLocations: mixed().optional(),
  validFrom: string().required("Start date is required"),
  validUntil: string().required("Expiry date is required"),
});

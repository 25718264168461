import React from 'react';
import Loader from "react-loader-spinner";

const Loading = ({ width, height, loading, className }) => {
  if (!loading) return <></>;

    return (
      <div className={className}>
        <Loader
          type="ThreeDots"
          color="#1171ef"
          height={height}
          width={width}

          //  timeout={3000} //3 secs
        />
      </div>
    );
};
export default Loading;

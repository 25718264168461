import { activityCustomDate } from "../../utilities";

export const NotificationType = {
  NEW_CUSTOMER: "NEW_CUSTOMER",
  NEW_VENDOR: "NEW_VENDOR",
  VENDOR_APPROVED: "VENDOR_APPROVED",
  UPDATE_VENDOR: "UPDATE_VENDOR",
  NEW_PRODUCT: "NEW_PRODUCT",
  PRODUCT_APPROVED: "PRODUCT_APPROVED",
  PRODUCT_CANCELLED: "PRODUCT_CANCELLED",
  NEW_ARTICLE: "NEW_ARTICLE",
  ARTICLE_PUBLISHED: "ARTICLE_PUBLISHED",
  ARTICLE_UNPUBLISHED: "ARTICLE_UNPUBLISHED",
};

export const getNotificationMessageAndLink = (notification) => {
  const {
    type,
    article,
    vendor,
    user,
    client,
    product,
    whatChanged,
    createdAt,
  } = notification;

  switch (type) {
    case NotificationType.NEW_CUSTOMER: {
      return {
        ...notification,
        message: `${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        } registered on ${activityCustomDate(createdAt)}`,
        link: `/dashboard/users`,
      };
    }
    case NotificationType.NEW_VENDOR: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        } recently joined as vendor`,
        link: `/dashboard/vendorstore`,
      };
    }
    case NotificationType.VENDOR_APPROVED: {
      return {
        ...notification,
        message: `${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        }'s vendor account approved`,
        link: `/dashboard/vendorstore`,
      };
    }
    case NotificationType.UPDATE_VENDOR: {
      if (whatChanged?.storeAddress) {
        return {
          ...notification,
          message: `${vendor?.firstName ?? ""} ${
            vendor?.lastName ?? ""
          } has changed the address <br> from ${
            whatChanged?.storeAddress?.from
          } <br> to ${
            whatChanged?.storeAddress?.to
          } on: <br> ${activityCustomDate(createdAt)}`,
          link: `/dashboard/vendorstore`,
        };
      }
    }
    case NotificationType.NEW_PRODUCT: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        } has added new product: <br> ${product?.name}`,
        link: `/dashboard/products`,
      };
    }
    case NotificationType.PRODUCT_APPROVED: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        }'s product has been approved <br> <b>${
          product?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/products`,
      };
    }
    case NotificationType.PRODUCT_CANCELLED: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        }'s product has been cancelled <br> <b>${
          product?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/products`,
      };
    }
    case NotificationType.NEW_ARTICLE: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has added new article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles`,
      };
    }
    case NotificationType.ARTICLE_PUBLISHED: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has published new article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles`,
      };
    }
    case NotificationType.ARTICLE_UNPUBLISHED: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has unpublished article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles`,
      };
    }
    default: return;
  }
};

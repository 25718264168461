/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Button
} from "reactstrap";
// core components
import Header from "./../../components/Headers/DefaultHeader.jsx";
import "../Setting/Settings.css";
import { connect } from "react-redux";
import { authSelector } from "./../../store/reducers";
import * as settingActions from "../../store/actions/settingActions";

class CommissionSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commission_for: "",
      commission_mode: "",
      commission_percent: "",
      shipping_goes_to_vendor: "",
      tax_goes_to_vendor: "",
      commission_after_vendor: "",
      commission_after_admin: "",
      enable: "",
      tax_label: "",
      tax_percent: ""
    };
  }

  handleSubmit = () => {
    const { token } = this.props.auth;
    this.props.addCommisionSettings(this.state, token);
  };

  handleInputChange = (e) => {
    if (e.target.type == 'checkbox') {
      this.setState({
        [e.target.name]: e.target.checked
      })
    }
    else {
      this.setState({
        [e.target.name]: e.target.value
      })
    } 
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt-7" fluid>
          {/* Table */}
          <div>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0"> Marketplace Commission Settings</h3>
                </CardHeader>

                {/* Store Settings */}

                <div className="mainWrapper">
                  <Form>
                    <h1></h1>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5"> Commission For</Col>
                      <Col sm="7">
                        <Input
                          type="select"
                          name="commission_for"
                          placeholder="Select"
                          value={this.state.commission_for}
                          onChange={this.handleInputChange}
                        >
                          <option>Select</option>
                          <option>User</option>
                          <option>Vendor</option>
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Commission Mode</Label>
                      </Col>

                      <Col sm="7">
                        <Input
                          type="select"
                          name="commission_mode"
                          placeholder="Select"
                          value={this.state.commission_mode}
                          onChange={this.handleInputChange}
                        >
                          <option>Select</option>
                          <option>Percent</option>
                          <option>Fixed</option>
                          <option>Percentage+Fixed</option>
                          <option>By Vendor Sales</option>
                          <option>By Product Price</option>
                          <option>By Purchase Quantity</option>
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Commission Percent(%)</Label>
                      </Col>

                      <Col sm="7">
                        <Input
                          type="text"
                          name="commission_percent"
                          value={this.state.commission_percent}
                          onChange={this.handleInputChange}
                        ></Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Shipping cost goes to vendor?</Label>
                      </Col>

                      <Col sm="7">
                        <input
                          type="checkbox"
                          name="shipping_goes_to_vendor"
                          value={this.state.shipping_goes_to_vendor}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Tax goes to vendor?</Label>
                      </Col>

                      <Col sm="7">
                        <input
                          type="checkbox"
                          name="tax_goes_to_vendor"
                          value={this.state.tax_goes_to_vendor}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Commission after consider vendor Coupon?</Label>
                      </Col>

                      <Col sm="7">
                        <input
                          type="checkbox"
                          name="commission_after_vendor"
                          value={this.state.commission_after_vendor}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="secondryWrapper">
                      <Col sm="5">
                        <Label>Commission after consider admin Coupon?</Label>
                      </Col>

                      <Col sm="7">
                        <input
                          type="checkbox"
                          name="commission_after_admin"
                          value={this.state.commission_after_admin}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                {/* Store Settings */}

                <CardHeader className="border-0">
                  <h3 className="mb-0"> Commission Tax Settings</h3>

                  <div className="mainWrapper">
                    <Form>
                      <h1></h1>

                      <FormGroup row className="secondryWrapper">
                        <Col sm="5">
                          <Label>Enable</Label>
                        </Col>

                        <Col sm="7">
                          <input
                            type="checkbox"
                            name="enable"
                            value={this.state.enable}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row className="secondryWrapper">
                        <Col sm="5">
                          <Label>Tax Label</Label>
                        </Col>

                        <Col sm="7">
                          <Input
                            type="text"
                            name="tax_label"
                            value={this.state.tax_label}
                            onChange={this.handleInputChange}
                          ></Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="secondryWrapper">
                        <Col sm="5">
                          <Label>Tax Percent(%)</Label>
                        </Col>

                        <Col sm="7">
                          <Input
                            type="text"
                            name="tax_percent"
                            value={this.state.tax_percent}
                            onChange={this.handleInputChange}
                          ></Input>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </CardHeader>

                <div className="text-center">
                  <Button
                    className="my-4"
                    onClick={this.handleSubmit}
                    color="primary"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {

  return {
    auth: authSelector(state),
    setting: state.setting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCommisionSettings: (settings, token) =>
      dispatch(settingActions.addCommisionSettings(settings, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionSetting);

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { auth } = { ...rest };
    return (
        <Route {...rest}
            render={props => {
                if (auth.isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/auth/login" />
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  makeStyles,
  AppBar,
} from "@material-ui/core";

import DefaultHeader from "../../components/Headers/DefaultHeader";
import Media from "./Media";
import Articles from "./Articles";
import Products from "./Products";
import Categories from "./Categories";
import StoreVendor from "./StoreVendor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Trash = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <DefaultHeader headerPadding={true} />
      <div className={classes.root}>
        <AppBar position="static" className="mb-3" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Media" {...a11yProps(0)} />
            <Tab label="Articles" {...a11yProps(1)} />
            <Tab label="Products" {...a11yProps(2)} />
            <Tab label="Categories" {...a11yProps(3)} />
            <Tab label="Store Vendor" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Media />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Articles />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Products />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Categories />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <StoreVendor />
        </TabPanel>
      </div>
    </div>
  );
};

export default Trash;

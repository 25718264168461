import * as types from "./../types/setting";

const initialState = {
  settings: [],
  loading: false,
  deliveryOptions: [],
  roles: [],
  error: "",
  config: {},
};

const settingReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case types.ADD_COMMISSION_SETTING_SUCCESS: {
      const settings = [...state.settings, actions.data];
      return {
        ...state,
        settings,
      };
    }
    case types.GET_ALL_DELIVERY_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.GET_ALL_DELIVERY_OPTIONS_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.ADD_OPTION:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_OPTION_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.ADD_OPTION_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.EDIT_OPTION:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_OPTION_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.EDIT_OPTION_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.DELETE_OPTION:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_OPTION_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.DELETE_OPTION_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.ADD_SECTION:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_SECTION_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.ADD_SECTION_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.DELETE_SECTION:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_SECTION_SUCCESS:
      return {
        ...state,
        deliveryOptions: actions.payload,
        loading: false,
      };
    case types.DELETE_SECTION_FAILURE:
      return {
        ...state,
        error: actions.payload,
        loading: false,
      };
    case types.RESET_SETTING: {
      return initialState;
    }
    case types.SETTING_FAILED: {
      return { ...state, loading: false };
    }
    case types.GET_CONFING: {
      return { ...state, loading: true };
    }
    case types.GET_CONFING_SUCCESS: {
      return { ...state, loading: false, config: actions.payload };
    }
    case types.SET_CONFING: {
      return { ...state, loading: true };
    }
    case types.SET_CONFING_SUCCESS: {
      return { ...state, loading: false, config: actions.payload };
    }
    default:
      return state;
  }
};

export default settingReducers;

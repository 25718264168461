import React, { useState } from "react";

const StnImage = ({
  src,
  className,
  placeholder,
  alt,
  width = 100,
  height = 100,
}) => {
  const [source, setSource] = useState(src ?? placeholder);
  return (
    <img
      src={source}
      className={className}
      alt={alt}
      width={width}
      height={height}
      onError={() => setSource(placeholder)}
    />
  );
};

export default StnImage;

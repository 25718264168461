import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";
import { couponType, formatDate } from "../../utilities";
import { Chip } from "@material-ui/core";

class CouponList extends Component {
  render() {
    const { coupons, handleEdit, handleDelete, start, end } = this.props;

    return (
      <tbody>
        {coupons &&
          coupons.slice(start, end).map((coupon) => {
            const newUser = coupon?.targetUser?.includes("newUser")
              ? "New User"
              : "";
            const registeredUser = coupon?.targetUser?.includes(
              "registeredUser"
            )
              ? "Registered User"
              : "";

            const targetUser = [newUser, registeredUser]
              .filter(Boolean)
              .join(", ");

            return (
              <tr key={coupon._id}>
                <td>{coupon.code}</td>
                <td>{targetUser}</td>
                <td>
                  {
                    couponType.find((opt) => opt.value == coupon.discountType)
                      ?.label
                  }
                </td>
                <td>
                  <span style={{ color: "#fb6340", fontSize: "15px" }}>
                    {coupon.discountType == "fixed" ? (
                      <b>${coupon.discountValue}</b>
                    ) : (
                      <b>{coupon.discountValue}%</b>
                    )}
                  </span>
                </td>
                <td>{coupon.maxUsage}</td>
                <td>{coupon.maxUsagePerUser}</td>
                <td
                  style={{ maxWidth: 300, gap: 5 }}
                  className="d-flex flex-wrap"
                >
                  {coupon.validLocations?.map((loc) => (
                    <Chip
                      label={`${loc?.line1} ${loc?.city} ${loc?.state} ${loc?.country}`}
                    />
                  ))}
                </td>
                <td>{coupon?.usedCount}</td>
                <td>{formatDate(coupon.validFrom)}</td>
                <td>{formatDate(coupon.validUntil)}</td>
                <td>{formatDate(coupon.createdAt)}</td>
                <td>
                  {coupon?.adminInfo?.firstName} {coupon?.adminInfo?.lastName}
                </td>
                <td>{coupon?.adminInfo?.email}</td>
                <td
                  style={{
                    minWidth: 300,
                    maxWidth: 500,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {coupon?.adminInfo?.address}
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light kb-topmenu"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => handleEdit(coupon._id)}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => handleDelete(coupon._id)}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default LoaderHOC("coupons", 6)(CouponList);

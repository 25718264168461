/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "./../../components/Headers/DefaultHeader.jsx";
import { connect } from "react-redux";
import ProductSearchField from "./../../components/SearchFields/ProductSearchField";
import * as Const from "../../utilities";
// import * as refundActions from "./../../store/actions/refundActions";
import { FaInfoCircle, FaEllipsisH } from "react-icons/fa";
import * as paymentActions from "../../store/actions/paymentActions";
import CustomPagination from "../Pagination/pagination";

class Refund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Refunds: [],
      modalIsOpen: false,
      product: {},
      imageModal: {},
      start: null,
      end: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  componentDidMount() {
    this.props.getRefund(this.props.auth?.token);
  }

  handleDelete = (id) => {
    const { token } = this.props.auth;
    this.props.deleteProduct(id, token);
    this.setState({ modalIsOpen: false });
  };

  handleEdit = (productId) => {
    this.props.history.push("/dashboard/add-product", productId);
  };

  convertdate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  paginationHandler = (start, end) => {
    this.setState({
      start,
      end,
    });
  };

  render() {
    const refunds = this.props.refunds;
    const { role } = this.props.auth;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Refund</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">REQUEST ID</th>
                      <th scope="col">ORDER ID</th>
                      <th scope="col">AMOUNT</th>
                      {/* <th scope="col">TYPE</th> */}
                      <th scope="col">REASON</th>
                      <th scope="col">DATE</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {!refunds && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <span style={{ textAlign: "center" }}>
                            No Items Found
                          </span>
                        </td>
                      </tr>
                    )}
                    {refunds &&
                      refunds
                        .slice(this.state.start, this.state.end)
                        .map((refund) => {
                          return (
                            <tr key={refund._id}>
                              <td>{refund.paymentIntentId}</td>
                              <td>{refund.orderItemId}</td>
                              <td>{refund.amount / 100}</td>
                              {/* <td>{refund.type ? refund.type : "N / A"}</td> */}
                              <td>{refund.reason ? refund.reason : "N / A"}</td>
                              <td>{this.convertdate(refund.createdAt)}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <CustomPagination
                      showPerPage={10}
                      paginationHandler={this.paginationHandler}
                      total={refunds?.length}
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    refunds: state.payment.refunds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRefund: (token) => dispatch(paymentActions.getRefund(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);

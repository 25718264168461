import * as types from "./../types/order";
import { medias } from "../services/order";
import { orders } from "../services/order";
import * as orderService from "../services/order"

export const getOrder = (token) => {
  return {
    type: types.GET_ORDER,
    token
  };
};
export const getOrderSuccess = orders => {
  return {
    type: types.GET_ORDER_SUCCESS,
    orders
  };
};

export const getOrderItemsSuccess = (orderItems) => {
  return {
    type: types.GET_ORDER_ITEMS_SUCCESS,
    payload: { orderItems }
  }
}

export const getOrderItemsFailure = () => {
  return {
    type: types.GET_ORDER_ITEMS_FAILURE,
  }
}

export const getMyOrder = (user) => {
  return {
    type: types.GET_MY_ORDER,
    payload: { user }
  };
};
export const getMyOrderSuccess = orders => {
  return {
    type: types.GET_MY_ORDER_SUCCESS,
    orders
  };
};

export const deleteOrder = (id, token) => {
  return {
    type: types.DELETE_ORDER,
    payload: { id, token }
  };
};
export const deleteOrderSuccess = id => {
  return {
    type: types.DELETE_ORDER_SUCCESS,
    id
  };
};

export const getSingleOrder = (id, token) => ({
  type: types.GET_SINGLE_ORDER,
  id,
  token,
});

export const getSingleOrderSuccess = (payload) => ({
  type: types.GET_SINGLE_ORDER_SUCCESS,
  payload,
});

export const orderRefund = (payload, token) => ({
  type: types.ORDER_REFUND,
  payload,
  token
});

export const orderRefundSuccess = (payload) => ({
  type: types.ORDER_REFUND_SUCCESS,
  payload,
});

export const userOrderRefund = (payload, token) => ({
  type: types.USER_ORDER_REFUND,
  payload,
  token
});

export const userOrderRefundSuccess = (payload) => ({
  type: types.USER_ORDER_REFUND_SUCCESS,
  payload,
});

export const getVendorOrders = (vendorId, token) => ({
  type: types.GET_VENDOR_ORDERS,
  vendorId,
  token
});

export const getVendorOrdersSuccess = (payload) => ({
  type: types.GET_VENDOR_ORDERS_SUCCESS,
  payload
});

export const getUserOrders = (userId, token) => ({
  type: types.GET_USER_ORDERS,
  userId,
  token,
});

export const getUserOrdersSuccess = (payload) => ({
  type: types.GET_USER_ORDERS_SUCCESS,
  payload,
});

export const cancelTheOrder = (orderItemId, token, resolve, reject) => ({
  type: types.CANCEL_THE_ORDER,
  orderItemId,
  token,
  resolve,
  reject,
});

export const cancelTheOrderSuccess = (payload) => ({
  type: types.CANCEL_THE_ORDER_SUCCESS,
  payload,
});

export const resetOrder = () => ({
  type: types.RESET_ORDER
});
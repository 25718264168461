import React, { useState } from "react";
import { Collapse, Button } from "reactstrap";
import { useSelector } from "react-redux";

import ProfileSection from "./profile";
import StoreSetting from "./StoreSetting";
import MemberShip from "./MemberShip";
import StoreOrders from "./StoreOrders";
import CommissionAndWithDrawal from "./CommissionAndWithDrawal";
import StoreHoursAndVacations from "./StoreHoursAndVacations";

const ManageVendor = (props) => {
  const {
    auth,
    vendorInfo,
    vendorData,
    updateVendor,
    vendorOrders,
    vendorOrdersLoading,
    getVendorOrders,
  } = props;

  const [openSection, setOpenSection] = useState({
    profile: false,
    storeSetting: false,
    commissionAndWithDrawal: false,
    StoreHoursAndVacation: false,
    storeSEOandSocial: false,
    MemberShip: false,
    storeOrders: false,
  });

  const onExpand = (property) => {
    setOpenSection((prevState) => {
      return { ...openSection, [property]: !prevState?.[property] };
    });
  };

  const collapseStyle = {
    marginBottom: "1rem",
    backgroundColor: "#1c2b36",
    color: "white",
  };

  return (
    <div className="p-2">
      <Button
        color="#1c2b36"
        className="btn btn-block text-left mb-0"
        onClick={() => onExpand("profile")}
        style={collapseStyle}
      >
        Profile
      </Button>
      <Collapse isOpen={openSection.profile}>
        <ProfileSection
          auth={auth}
          vendorData={vendorData?.vendor}
          updateVendor={updateVendor}
        />
      </Collapse>
      <Button
        color="#1c2b36"
        className="mt-3 btn btn-block text-left mb-0"
        onClick={() => onExpand("storeSetting")}
        style={collapseStyle}
      >
        Store Settings
      </Button>
      <Collapse isOpen={openSection.storeSetting}>
        <StoreSetting />
      </Collapse>
      <Button
        color="#1c2b36"
        className="mt-3 btn btn-block text-left"
        onClick={() => onExpand("commissionAndWithDrawal")}
        style={collapseStyle}
      >
        Commission & Withdrawal
      </Button>
      <Collapse isOpen={openSection.commissionAndWithDrawal}>
        <CommissionAndWithDrawal />
      </Collapse>
      <Button
        color="#1c2b36"
        className="btn btn-block text-left"
        onClick={() => onExpand("StoreHoursAndVacation")}
        style={collapseStyle}
      >
        Store Hours & Vacation
      </Button>
      <Collapse isOpen={openSection.StoreHoursAndVacation}>
        <StoreHoursAndVacations />
      </Collapse>
      <Button
        color="#1c2b36"
        className="btn btn-block text-left"
        onClick={() => onExpand("storeSEOandSocial")}
        style={collapseStyle}
      >
        Store SEO & Social
      </Button>
      <Collapse isOpen={openSection.storeSEOandSocial}>
        <h1>hiii from Store SEO & Social</h1>
      </Collapse>
      <Button
        color="#1c2b36"
        className="btn btn-block text-left"
        onClick={() => onExpand("MemberShip")}
        style={collapseStyle}
      >
        Membership
      </Button>
      <Collapse isOpen={openSection.MemberShip}>
        <MemberShip />
      </Collapse>
      <Button
        color="#1c2b36"
        className="btn btn-block text-left"
        onClick={() => onExpand("storeOrders")}
        style={collapseStyle}
      >
        Store-Orders
      </Button>
      <Collapse isOpen={openSection.storeOrders}>
        {openSection.storeOrders && (
          <StoreOrders
            auth={auth}
            vendorInfo={vendorInfo}
            vendorOrders={vendorOrders}
            vendorOrdersLoading={vendorOrdersLoading}
            getVendorOrders={getVendorOrders}
          />
        )}
      </Collapse>
    </div>
  );
};

export default ManageVendor;

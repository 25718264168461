import React, { memo } from "react";

export default memo(function AddVideo({
  register,
  errors,
  defaultFieldVideoURL,
  videoUrls,
  setVideoUrls,
  appendVideoURL,
  removeVideoURL,
}) {
  const addField = () => {
    let id = Date.now();
    let newDefault = { ...defaultFieldVideoURL, _id: id };
    setVideoUrls([...videoUrls, newDefault]);
    appendVideoURL(newDefault);
  };
  const removeField = (index) => {
    removeVideoURL(index);
    setVideoUrls((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  return (
    <div>
      <div className="mt-10 ">
        <label className="font-18"> Video URL:</label>
      </div>
      <div className="border-1 p-10">
        {videoUrls.map((field, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between">
              <span>{index + 1}#</span>
              <i
                className="fas fa-trash text-danger"
                onClick={() => removeField(index)}
              />
            </div>
            <div className="p-10 mx-40 ">
              <input
                type="hidden"
                name="_id"
                value={field._id}
                {...register(`videoUrls.${index}._id`, {
                  onChange: (e) => {
                    e.persist();
                    setVideoUrls((prevFields) => {
                      const updatedFields = [...prevFields];
                      updatedFields[index]._id = e.target.value;
                      return updatedFields;
                    });
                  },
                })}
              />
              <div className="mt-10">
                <label className="font-18">Video title:</label>
              </div>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Video Title...."
                  className="input form-control"
                  name="title"
                  value={field.title}
                  {...register(`videoUrls.${index}.title`, {
                    onChange: (e) => {
                      e.persist();
                      setVideoUrls((prevFields) => {
                        const updatedFields = [...prevFields];
                        updatedFields[index].title = e.target.value;
                        return updatedFields;
                      });
                    },
                  })}
                />
              </div>
              <div className="text-danger text-sm ml-3 pt-1">
                {errors?.videoUrls?.[index]?.title && (
                  <span>{errors.videoUrls[index].title.message}</span>
                )}
              </div>
              <div className="mt-10">
                <label className="font-18">Video description:</label>
              </div>
              <div className="input-wrapper">
                <textarea
                  className="input form-control"
                  value={field.description}
                  name="description"
                  placeholder="Video Description...."
                  {...register(`videoUrls.${index}.description`, {
                    onChange: (e) => {
                      e.persist();
                      setVideoUrls((prevFields) => {
                        const updatedFields = [...prevFields];
                        updatedFields[index].description = e.target.value;
                        return updatedFields;
                      });
                    },
                  })}
                />
              </div>
              <div className="text-danger text-xs ml-3 pt-1 ">
                {errors?.videoUrls?.[index]?.description && (
                  <span>{errors.videoUrls[index].description.message}</span>
                )}
              </div>
              <div className="mt-10 ">
                <label className="font-18">Video URL:</label>
              </div>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Video URL...."
                  className="input form-control"
                  name="url"
                  value={field.url}
                  {...register(`videoUrls.${index}.url`, {
                    onChange: (e) => {
                      e.persist();
                      setVideoUrls((prevFields) => {
                        const updatedFields = [...prevFields];
                        updatedFields[index].url = e.target.value;
                        return updatedFields;
                      });
                    },
                  })}
                />
              </div>
              <div className="text-danger text-xs ml-3 pt-1">
                {errors?.videoUrls?.[index]?.url && (
                  <span>{errors.videoUrls[index].url.message}</span>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="p-10 mb-40">
          <button
            className="btn btn-sm btn-primary ml-10 float-right"
            type="button"
            onClick={() => addField()}
          >
            + Add
          </button>
        </div>
      </div>
    </div>
  );
});

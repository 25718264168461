import React from "react";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";

import ProductImageSlider from "../Carousel/ProductCarousel";

const PreviewModal = ({ productInfo, onHide }) => {
  const { name, description, price, finalPrice } = productInfo;

  return (
    <Modal centered show={true} onHide={onHide}>
      <div className="d-flex justify-content-end">
        <Close onClick={onHide} className="cursor-pointer mr-2 mt-2" />
      </div>
      <Modal.Body className="p-2">
        <ProductImageSlider product={productInfo} type="preview" />
        <div className="mx-3 mb-3">
          <div
            className="text-md font-semibold"
            dangerouslySetInnerHTML={{ __html: name }}
          />
          <div
            className="text-base mt-1"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="font-bold mt-2">
            <b>Price:</b>{" "}
            <span
              style={{ textDecoration: "line-through" }}
              className="font-bold text-danger"
            >
              ${price}
            </span>{" "}
            <span className="font-semibold text-[#019B5D]">${finalPrice}</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewModal;

import React ,{useState,useEffect}from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import {useSelector} from "react-redux";

const CommissionAndWithDrawal = () =>{
    const vendorDetails = useSelector(state => {
        return state["user"].vendor;
    })

    const [vendor,setVendor] = useState({
        firstName:'',
        lastName:''
    })
    useEffect(()=>{
        setVendor({
            ...vendor,
            storeName: vendorDetails?.vendor?.storeName,
            email: vendorDetails?.vendor?.email,
            contact: vendorDetails?.vendor?.contact
        })
    },[vendorDetails])
    let updateInput = (e) =>{
        setVendor({...vendor,[e.target.name] : e.target.value})
    }
    return(
        <Card className="mt-0 mb-3">
            {/*<h1>{JSON.stringify(vendor)}</h1>*/}
            <CardBody>
                <form className="form" action="" >

                    {/*Commission Setup starts from here*/}

                    <div className="form-heading">
                        <h1 className={"font-weight-bold text-primary"}>Commission Setup</h1>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="firstName" className="font-weight-normal">
                            Commission For<small className="text-danger">*</small>
                        </label>
                        <input readOnly value={"vendor"} onChange={updateInput} name="firstName" type="text" className="form-control w-50"/>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <label htmlFor="bannerType" className="font-weight-normal">
                            Commission Mode<small className="text-danger">*</small>
                        </label>
                        <select
                            name="bannerType"
                            value={""}
                            readOnly={true}
                            required
                            className={"form-control w-50"}
                        >
                            <option value="staticImage">By Global Rule</option>
                            <option value="staticImage">Percent</option>
                            <option value="staticImage">Fixed</option>
                            <option value="staticImage">Percent + Fixed</option>
                            <option value="staticImage">Percent</option>
                            <option value="staticImage">By Vendor Sales</option>
                            <option value="staticImage">By Product Price</option>
                            <option value="staticImage">By Purchase Quantity</option>
                        </select>
                    </div>
                </form>
                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>

                {/*Transaction Charges starts from here*/}

                <div className="form-heading mt-4">
                    <h1 className={"font-weight-bold text-primary"}>Transaction Charges</h1>
                    <small className="text-muted">These charges will be deducted from vendor's total order commission depending upon Order Payment Method.
                    </small>
                </div>
                <div className="form-group d-flex align-items-center justify-content-between mt-5">
                    <label htmlFor="bannerType" className="font-weight-normal">
                        Transactional Charge Mode<small className="text-danger">*</small>
                    </label>
                    <select
                        name="bannerType"
                        value={""}
                        readOnly={true}
                        required
                        className={"form-control w-50"}
                    >
                        <option value="staticImage">By Global Rule</option>
                        <option value="staticImage">Vendor Specific Rule</option>
                    </select>
                </div>
                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>

                {/*Withdrawal Setup starts from here*/}

                <div className="form-heading mt-4">
                    <h1 className={"font-weight-bold text-primary"}>Withdrawal Setup</h1>
                </div>
                <div className="form-group d-flex align-items-center justify-content-between mt-5">
                    <label htmlFor="bannerType" className="font-weight-normal">
                        Withdrawal Mode<small className="text-danger">*</small>
                    </label>
                    <select
                        name="bannerType"
                        value={""}
                        readOnly={true}
                        required
                        className={"form-control w-50"}
                    >
                        <option value="staticImage">By Global Rule</option>
                        <option value="staticImage">Vendor Specific Rule</option>
                    </select>
                </div>
                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>

                {/*Payment Setup starts from here*/}

                <div className="form-heading mt-4">
                    <h1 className={"font-weight-bold text-primary"}>Payment Setup</h1>
                </div>
                <div className="form-group d-flex align-items-center justify-content-between mt-5">
                    <label htmlFor="bannerType" className="font-weight-normal">
                        Prefered Payment Method<small className="text-danger">*</small>
                    </label>
                    <select
                        name="bannerType"
                        value={""}
                        readOnly={true}
                        required
                        className={"form-control w-50"}
                    >
                        <option value="">Choose Withdrawal Payment Method</option>
                        <option value="staticImage">Paypal</option>
                        <option value="staticImage">Bank Transfer</option>
                    </select>
                </div>
                <div className="d-flex align-items-end justify-content-end mt-3">
                    <button className="btn btn-dark">
                        update
                    </button>
                </div>
            </CardBody>
        </Card>
    )
}
export default CommissionAndWithDrawal

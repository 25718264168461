import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types/payment";
import * as actions from "../actions/paymentActions";
import * as service from "../services/paymentService";
import { toast } from "react-toastify";
import { get } from "../lib/Api";
import { errorResponse } from "./auth";

function* getVendorPaymentSaga(action) {
  try {
    const { id } = action.payload;
    const response = yield call(service.getVendorPayment, id);
    if (response.status === 201) {
      yield put(actions.getVendorPaymentSuccess(response.data.message));
    }
  } catch (error) {
    yield put({ type: types.GET_VENDOR_PAYMENT_FAILURE, error });
  }
}

function* getAdminPaymentSaga(action) {
  try {
    const response = yield call(service.getAdminPayment);
    if (response.status === 201) {
      yield put(actions.getAdminPaymentSuccess(response.data.message));
    }
  } catch (error) {
    yield put({ type: types.GET_ADMIN_PAYMENT_FAILURE, error });
  }
}

function* vendorRefundSaga(action) {
  try {
    const response = yield call(service.vendorRefund, action.payload);

    if (response.status === 201) {
      yield put(actions.vendorRefundSuccess(response.data.message));
      toast.success("Refunded Successfull!");
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.VENDORE_REFUND_FAILURE, error });
  }
}

function* getVendorRefundSaga(action) {
  try {
    const response = yield call(service.getVendorRefund, action.payload);
    if (response.status === 201) {
      yield put(actions.getVendorRefundSuccess(response.data.message));
    }
  } catch (error) {
    yield put({ type: types.GET_VENDORE_REFUND_FAILURE, error });
  }
}

function* getRefund({ token }) {
  try {
    const headers = { authorization: `Bearer ${token}` };
    const result = yield call(() => get("/order/refund", headers));
    yield put(actions.getRefundSuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}

export default function* paymentWatcher() {
  yield takeLatest(types.GET_VENDOR_PAYMENT, getVendorPaymentSaga);
  yield takeLatest(types.GET_ADMIN_PAYMENT, getAdminPaymentSaga);
  yield takeLatest(types.VENDORE_REFUND, vendorRefundSaga);
  yield takeLatest(types.GET_VENDORE_REFUND, getVendorRefundSaga);
  yield takeLatest(types.GET_REFUND, getRefund);
}

import { convertObjectToParamUrl } from "../../utilities";
import API, { del, HeadersOrigin } from "../lib/Api";

export async function getVendor(token, queryParams) {
  let query;
  if (queryParams) {
    query = convertObjectToParamUrl({ vendor: true, ...queryParams });
  }
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/users${query ? query : ""}`, { headers });
}
export async function getAllUsers(token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get("/users", { headers });
}
export async function getVendorStats(vendorId, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/products/vendor/${vendorId}`, { headers });
}
export async function getSingleVendor(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/users?vendor=true&id=${id}`, { headers });
}
export async function getUser(token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get("/users?user=true", { headers });
}
export async function deleteUser(_id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/users/activate/${_id}`, { headers });
}
export async function deleteUserPermanently(_id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.delete(`/users/permanent/${_id}`, { headers });
}
export async function getRoles(token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/roles`, { headers });
}

export async function activateVendor(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/users/activate/${id}`, { headers });
}
export async function updateUserRole(userId, roleId, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`/users/update-user-role`, { userId, roleId }, { headers });
}
export async function updateUserPassword(userId, password, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(
    `/users/update-user-password`,
    { userId, password },
    { headers }
  );
}
export async function getMedia(token, query) {
  const params = convertObjectToParamUrl(query);
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/roles/media${params}`, { headers });
}
export async function deleteMedia(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/roles/media_delete/${id}`, headers);
}
export async function changeStatus(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`users/vendor/status`, data, { headers });
}

export async function addUser(payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  delete payload?.roleName;
  return API.post(`/users/add-user`, payload, { headers });
}

export async function updateUser(id, payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  delete payload?.roleName;
  return API.put(`/users/update-user/${id}`, payload, { headers });
}

export async function addFlagToMedia(id, payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`/users/flagToMedia/${id}`, payload, { headers });
}

export async function trashMedia(id, payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`/users/trash-media/${id}`, payload, { headers });
}

export async function trashStore(id, payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`/users/trash-store/${id}`, payload, { headers });
}

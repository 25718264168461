import { del, get, HeadersOrigin, patch, post } from "./../lib/Api";

export async function coupons(token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };

  return get("/coupon", headers);
}

export async function deleteCoupons(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/coupon/${id}`, headers);
}

export async function addCoupon(coupon, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/coupon", coupon, headers);
}

export async function updateCoupon(id, coupon, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/coupon/${id}`, coupon, headers);
}

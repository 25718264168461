/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Button,
    Col
} from "reactstrap";
// core components
import Header from "./../../components/Headers/DefaultHeader.jsx";
import { connect } from "react-redux";
import SearchField from "./../../components/SearchFields";
import SelectorField from "./../../components/SearchFields/Selector";
import ShowEntries from "../../components/SearchFields/ShowEntries";
import * as productActions from "./../../store/actions/productActions";
import * as Const from "../../utilities";
import { FaInfoCircle, FaEye, FaFileInvoice } from "react-icons/fa";
import { TiImage } from "react-icons/ti";
import Modal from "../../components/Modal/ProductModal";
import LedgerList from "./LedgerList"
import Ticket from "./Tickets";
import * as paymentActions from '../../store/actions/paymentActions';

import Loader from 'react-loader-spinner'

import "./ledgerStyle.css";

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // products: this.props.product.products,
            modalIsOpen: false,
            product: {},
            imageModal: {},
            searching: false
        };
    }
    componentDidMount() {
        this.props.auth.role === "vendor" ?
            this.props.getVendorPayment(this.props.auth.user) :
            this.props.getPayment();

        this.props.getVendorRefund(this.props.auth.user);
    }
    convertDate = dt => {
        const date = new Date(dt);
        return date.toDateString();
    };


    openModal = product => {
        const { _id } = product;
        const imageModal = product.productPic[0].img.split("public/").pop();
        this.setState({ modalIsOpen: true, product, imageModal });
        const { token } = this.props.auth;
        this.props.addView(_id, token);
    };
    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    handleDelete = id => {
        const { token } = this.props.auth;
        this.setState({ modalIsOpen: false });
        this.props.deleteProduct(id, token);
    };

    handleAddProduct = () => {
        const { role } = this.props.auth;
        this.props.history.push("/dashboard/add-product");
    };

    nameSearch = ({ target: input }) => {
        const { products } = this.props.product;
        if (input.value && input.value !== "") {
            let searchText = input.value.toLowerCase();
            const filterProducts = products.filter(product => {
                return product.name.toLowerCase().indexOf(searchText) >= 0;
            });
            this.setState({ products: filterProducts, searching: true });
        } else {
            this.setState({
                products: this.props.product.products,
                searching: false
            });
        }
    };
    categorySelect = ({ target: input }) => {
        const { products } = this.props.product;
        if (input.value && input.value !== "") {
            const filter = products.filter(e => {
                return e.category._id === input.value;
            });
            this.setState({ products: filter, searching: true });
        } else {
            this.setState({
                products: this.props.product.products,
                searching: false
            });
        }
    };
    colorSelect = ({ target: input }) => {
        const { products } = this.props.product;
        if (input.value && input.value !== "") {
            const filter = products.filter(e => e.color === input.value);
            this.setState({ products: filter, searching: true });
        } else {
            this.setState({
                products: this.props.product.products,
                searching: false
            });
        }
    };
    changeSearch = ({ target: input }) => {
        // const products = this.props.product.products;
        // if (input.value && input.value !== "") {
        //   let searchText = input.value.toLowerCase();
        //   const filterProducts = products.filter(el => {
        //     return (
        //       el.name.toLowerCase().indexOf(searchText) >= 0 ||
        //       // el.category.indexOf(searchText) >= 0 ||
        //       el.color.toLowerCase().indexOf(searchText) >= 0
        //     );
        //   });
        //   this.setState({ products: filterProducts });
        // } else {
        //   this.setState({ products: this.props.product.products });
    };
    changeSearchText = ({ target: input }) => {
        const products = this.props.product.products;
        if (input.value && input.value !== "") {
            let searchText = input.value.toLowerCase();
            const filterProduct = products.filter(el => {
                let searchValue = el.name.toLowerCase();

                return (
                    el.name.toLowerCase().indexOf(searchText) >= 0
                );
            });
            this.setState({ products: filterProduct });
        } else {
            this.setState({ products: this.props.product.products });
        }
    };
    handleEdit = productId => {
        this.props.history.push("/dashboard/add-product", productId);
    };

    getEarning = () => {
        const { payment } = this.props.payment;
        let earning = 0;
        payment.forEach(invoice => {
            earning += invoice.payment;
        })
        return earning;
    }

    getRefunding = () => {
        const { refund } = this.props.payment;
        let refunding = 0;
        refund.forEach(payback => {
            refunding += payback.amount;
        });
        return refunding
    }

    render() {
        const { payment, status, refund } = this.props.payment
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Ledger Book</h3>
                                </CardHeader>
                                <div className="tickets">
                                    <Ticket type="earning" amount={this.getEarning()} color="#38b7ef" />
                                    <Ticket type="withdrawal" amount="0.00" color="#42b77a" />
                                    <Ticket type="refund" amount={this.getRefunding()} color="#ea2a94" />
                                </div>
                                <Table
                                    className="align-items-center mainTable table-flush table table-sm"
                                    responsive
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            {/* <th scope="col">
                                                {" "}
                                                <TiImage size={"20px"} />
                                            </th> */}
                                            <th scope="col">TYPE</th>
                                            <th scope="col">DETAILS</th>
                                            <th scope="col">CREDIT</th>
                                            <th scope="col">DEBIT</th>
                                            <th scope="col">DATED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!status.isLoaded && status.isLoading && <tr><td colSpan={5} className="text-center"><Loader type="ThreeDots" color="#1171ef" height={70} width={70} />
                                            </td></tr> }
                                        {
                                            status.isLoaded && payment &&
                                            payment.map(invoice => {
                                                return (
                                                    <tr key={invoice._id}>
                                                        <td>{invoice.mode}</td>
                                                        <td>{invoice.note}</td>
                                                        <td>{invoice.payment}</td>
                                                        <td> - </td>
                                                        <td>{this.convertDate(invoice.date)}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        {
                                            status.isLoaded && payment &&
                                            refund.map(invoice => {
                                                return (
                                                    <tr key={invoice._id}>
                                                        <td>Refund</td>
                                                        <td>{invoice.reason ? invoice.reason : "N/A"}</td>
                                                        <td> - </td>
                                                        <td>{invoice.amount}</td>
                                                        <td>{this.convertDate(invoice.createdAt)}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem className="disabled">
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem className="active">
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    1
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    2 <span className="sr-only">(current)</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    3
                                            </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </Card>
                            <Modal
                                modalIsOpen={this.state.modalIsOpen}
                                closeModal={this.closeModal}
                                product={this.state.product}
                                image={this.state.imageModal}
                                handleDelete={this.handleDelete}
                                handleEdit={this.handleEdit}
                            />
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        payment: state.payment,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVendorPayment: id => { dispatch(paymentActions.getVendorPayment(id)) },
        getPayment: () => { dispatch(paymentActions.getAdminPayment) },
        getVendorRefund: id => { dispatch(paymentActions.getVendorRefund(id)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

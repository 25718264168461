import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Collapse } from "reactstrap";
import { Check } from "@material-ui/icons";

import { customDate, deliveryDate } from "../../utilities";

const Item = ({ data, onUpdateReview }) => {
  const {
    order,
    orderItemId,
    orderItem,
    clientInfo,
    vendorInfo,
    overallExperience,
    vendor,
    note,
  } = data;
  const [collapse, setCollapse] = useState(false);
  const [message, setMessage] = useState(note ?? "");

  return (
    <tr>
      <td style={{ minWidth: "250px", maxWidth: "250px", textWrap: "wrap" }}>
        <Collapse isOpen={collapse}>
          <div className="d-flex mb-2" style={{ gap: "4%" }}>
            <span
              className="cursor-pointer text-primary"
              onClick={() => setCollapse(!collapse)}
              style={{ width: "48%" }}
            >
              #{order?.orderNo}
            </span>
            <div style={{ width: "48%" }}>
              <b>Delivery Date:&nbsp;</b>
              <span
                dangerouslySetInnerHTML={{
                  __html: deliveryDate(orderItem?.pickupDate),
                }}
              />
            </div>
          </div>
          <div className="d-flex" style={{ gap: "4%" }}>
            <div style={{ width: "48%" }}>{orderItem?.product?.name}</div>
            <div style={{ width: "48%" }}>
              <b>Ordered Date:&nbsp;</b>
              <span
                dangerouslySetInnerHTML={{
                  __html: customDate(orderItem?.createdAt),
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <u>Vendor</u>
            {" => "}
            <b>
              {vendorInfo?.firstName} {vendorInfo?.lastName}:
            </b>
            <pre
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: "0.8125rem",
                color: "#525f7f",
                margin: 0,
              }}
            >
              #1. {vendor?.description}
            </pre>
            <b>Rating:</b> {vendor?.rating}
          </div>
          <div className="mt-4">
            <u>Customer</u>
            {" => "}
            <b>
              {clientInfo?.firstName} {clientInfo?.lastName}:
            </b>
            <pre
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: "0.8125rem",
                color: "#525f7f",
                margin: 0,
              }}
            >
              #1. {overallExperience?.description}
            </pre>
            <b>Rating:</b> {overallExperience?.rating}
          </div>
        </Collapse>
        <span
          className={`cursor-pointer text-primary ${collapse && "d-none"}`}
          onClick={() => setCollapse(!collapse)}
        >
          #{order?.orderNo}
        </span>
      </td>
      <td className="d-flex align-items-center" style={{ gap: "10px" }}>
        <input
          className="form-control"
          placeholder="Enter note"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Check onClick={() => onUpdateReview({ orderItemId, note: message })} />
      </td>
      <td>
        <Button size="sm">Do Something</Button>
      </td>
    </tr>
  );
};

export default Item;

import React from "react";
import { Close } from "@material-ui/icons";
import { Button, Modal } from "react-bootstrap";

const InvoiceDialog = ({ onClose, dataHtml }) => {
  return (
    <Modal
      className="invoice-modal"
      dialogClassName="dialog"
      show={true}
      onHide={onClose}
    >
      <Modal.Header>
        <Button
          onClick={() => {
            const id = "receipt";
            const iframe = document.frames
              ? document.frames[id]
              : document.getElementById(id);
            const iframeWindow = iframe.contentWindow || iframe;

            iframe.focus();
            iframeWindow.print();
          }}
        >
          Download
        </Button>
        <Close onClick={onClose} className="cursor-pointer" />
      </Modal.Header>
      <div className="h-100 w-100">
        <iframe
          id="receipt"
          srcDoc={dataHtml}
          style={{
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            display: "block",
            minHeight: "88.5vh",
          }}
        />
      </div>
    </Modal>
  );
};

export default InvoiceDialog;

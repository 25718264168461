/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import React from "react";

// import {
//   Card,
//   CardHeader,
//   CardFooter,
//   DropdownMenu,
//   DropdownItem,
//   UncontrolledDropdown,
//   DropdownToggle,
//   Pagination,
//   PaginationItem,
//   PaginationLink,
//   Table,
//   Container,
//   Row
// } from "reactstrap";
// core components

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";
import Header from "../../components/Headers/DefaultHeader.jsx";
import ProductSearchField from "./../../components/SearchFields/ProductSearchField";
import * as productActions from "./../../store/actions/productActions";
import * as Const from "../../utilities";
import { FaInfoCircle, FaEllipsisH } from "react-icons/fa";
import { TiImage } from "react-icons/ti";
import Modal from "./../../components/Modal/ProductModal";
import * as paymentActions from "../../store/actions/paymentActions";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
    };
  }
  componentDidMount() {
    this.props.auth.role === "vendor"
      ? this.props.getVendorPayment(this.props.auth.user)
      : this.props.getPayment();
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {};
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  getEarning = () => {
    const { payment } = this.props.payment;
    let earning = 0;
    payment.forEach((invoice) => {
      earning += invoice.payment;
    });
    return earning;
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {/* <Row> */}
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales reports by</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: this.state.activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => this.toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: this.state.activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => this.toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={chartExample1[this.state.chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* </Row> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    payment: state.payment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorPayment: (id) => {
      dispatch(paymentActions.getVendorPayment(id));
    },
    getPayment: () => {
      dispatch(paymentActions.getAdminPayment());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

import * as types from "./../types/product";

const initialState = {
  products: [],
  loading: false,
  error: "",
  formData: null,
  addProductLoading: false,
};

const productReducers = (state = initialState, actions) => {
  switch (actions.type) {
    // Failed List Articles
    case types.LIST_PRODUCT_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false
      };
    }
    case types.ADD_UPDATE_PRODUCT_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
        addProductLoading: false,
      };
    }
    case types.GET_PRODUCTS:
      return {
        ...state,
        loading: true
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: actions.products,
        loading: false
      };
    case types.ADD_TO_STORE_SUCCESS: {
      const products = [...state.products];
      const index = products.findIndex((e) => e._id === actions.data._id);
      products[index].isPublish = actions.data.isPublish;
      return { ...state, products, loading: false };
    }

    case types.ADD_PRODUCT_VIEW_SUCCESS: {
      const products = [...state.products];
      const index = products.findIndex((e) => e._id === actions.data._id);
      products[index].views = actions.data.views;
      return { ...state, products, loading: false };
    }
    case types.GET_MY_PRODUCT_SUCCESS:
      return {
        ...state,
        products: actions.products,
        loading: false
      };
    case types.DELETE_PRODUCT:
      case types.TRASH_PRODUCT: {
      return {
        ...state,
        loading: true
      };
    }
    case types.DELETE_PRODUCT_SUCCESS:
      case types.TRASH_PRODUCT_SUCCESS: {
      const payload = actions.payload;
      const products = state?.products ?? [];
      const filteredProducts = products.filter((e) => e._id !== payload?._id);
      return (state = {
        ...state,
        products: filteredProducts,
        loading: false
      });
    }
    case types.CHANGE_STATUS: {
      return {
        ...state,
        loading: true
      }
    }
    case types.CHANGE_STATUS_SUCCESS: {
      const { id, status } = actions.payload ?? {};
      const products = state?.products ?? [];
      const filteredProducts = products.map((e) => {
        if(e._id === id) {
          return { ...e, status }
        } else {
          return e;
        }});
      return (state = {
        ...state,
        products: filteredProducts,
        loading: false
      });
    }
    case types.ADD_PRODUCT: {
      return {
        ...state,
        addProductLoading: true
      };
    }

    case types.ADD_PRODUCT_SUCCESS: {
      const products = [...state.products, actions.data];
      return {
        ...state,
        products,
        addProductLoading: false
      };
    }
    case types.UPDATE_PRODUCT: {
      return {
        ...state,
        addProductLoading: true
      };
    }
    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        addProductLoading: false
      };
    case types.ADD_FLAG_SUCCESS: {
      const payload = actions.payload;
      const filteredProducts = state.products.map((ele) => {
        if(ele?._id == payload?._id) {
          return { ...ele, ...payload }
        } else {
          return ele;
        }
      });
      return {
        ...state,
        products: filteredProducts
      };
    }
    case types.SAVE_FORM_DATA:
      return { ...state, formData: actions.payload };
    case types.RESET_PRODUCT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default productReducers;

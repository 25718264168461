import React from "react";
import { Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import Sidebar from "../components/Sidebar/Sidebar.jsx";
import ArticleManagerNavbar from "../components/Navbars/ArticleManagerNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import CreateArticle from "../views/Article/CreateArticle.js";
import Articles from "../views/Article/Articles.js";
import { articleManagerRoutes } from "../routes.js";
import ProtectedRoute from "../protected.route";

const ArticleManager = (props) => {
  const { location, auth } = props;

  const getBrandText = (path) => {
    for (let i = 0; i < articleManagerRoutes.length; i++) {
      if (
        location.pathname.indexOf(
          articleManagerRoutes[i].layout + articleManagerRoutes[i].path
        ) !== -1
      ) {
        return articleManagerRoutes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        auth={auth}
        routes={articleManagerRoutes}
        logo={{
          innerLink: "/article-manager/articles",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content">
        <ArticleManagerNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          <ProtectedRoute
            path="/article-manager/articles"
            role="article-manager"
            component={Articles}
          />
          <ProtectedRoute
            path="/article-manager/add-article"
            component={CreateArticle}
          />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleManager);

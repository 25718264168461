import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Button,
    Col
} from "reactstrap";
import { LoaderHOC } from "../../components/Hoc/LoaderHoc"
import * as Const from "../../utilities";

class ProductList extends Component {

    convertDate = dt => {
        const date = new Date(dt);
        return date.toDateString();
    };

    render() {
        const { products, role, openModal, handleDelete, handleEdit } = this.props
        return (
            <tbody>
                {products &&
                    products.map(product => {
                        return (
                            <tr key={product._id}>
                                <td>
                                    <span style={{ color: "green" }}>
                                        In Stock <br />
                                    </span>
                                    <span style={{ color: "gray" }}>
                                        {product.stock}
                                    </span>
                                    {/* {product.stock} */}
                                </td>
                                <td>
                                    <span
                                        style={{
                                            textDecoration: "line-through",
                                            color: "#fb6340"
                                        }}
                                    >
                                        ${product.price} <br />
                                    </span>
                                    <span style={{ color: "green" }}>
                                        ${product.finalPrice} <br />
                                    </span>
                                    {/* {product.price} */}
                                </td>

                                <td>
                                    {/* {product.category && product.category.name} */}
                                    <span style={{ color: "gray" }}>
                                        {" "}
                                categories : <br />
                                    </span>
                                    <span style={{ color: "green" }}>
                                        {product.category && product.category.name}
                                    </span>
                                </td>
                                <td>{product.views ? product.views : 0}</td>

                                <td>{this.convertDate(product.createdAt)}</td>
                            </tr>
                        );
                    })}

            </tbody>
        );
    }
}

export default LoaderHOC('products')(ProductList);
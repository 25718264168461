/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import Header from "../../components/Headers/DefaultHeader.jsx";
import { connect } from "react-redux";
import { FaEllipsisH } from "react-icons/fa";
import * as paymentActions from '../../store/actions/paymentActions';
import Loader from 'react-loader-spinner'

class Payment extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.auth.role === "vendor" ?
      this.props.getVendorPayment(this.props.auth.user) :
      this.props.getPayment();
  }
  convertDate = dt => {
    const date = new Date(dt);
    return date.toDateString();
  };

  render() {
    const { payment, status } = this.props.payment
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Payment</h3>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">
                        {" "}
                        <FaEllipsisH color="green" />
                      </th>
                      <th scope="col">INVOICE ID</th>
                      <th scope="col">ORDER ID</th>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">CHARGES</th>
                      <th scope="col">PAYMENT</th>
                      <th scope="col">MODE</th>
                      <th scope="col">NOTE</th>
                      <th scope="col">DATE</th>
                    </tr>
                  </thead>

                  <tbody style={{ textAlign: "center" }}>
                    {!status.isLoaded && status.isLoading && (
                      <tr>
                        <td colSpan={9} className="text-center">
                          <Loader
                            type="ThreeDots"
                            color="#1171ef"
                            height={70}
                            width={70}
                          />
                        </td>
                      </tr>
                    )}
                    {status.isLoaded &&
                      payment &&
                      payment.map((invoice) => {
                        return (
                          <tr key={invoice._id}>
                            <td>
                              <FaEllipsisH color="green" />
                            </td>
                            <td>{invoice.invoiceId}</td>
                            <td>{invoice.orderId}</td>
                            <td>{invoice.amount}</td>
                            <td>{invoice.charges}</td>
                            <td>{invoice.payment}</td>
                            <td>{invoice.mode}</td>
                            <td>{invoice.note}</td>
                            <td>{this.convertDate(invoice.date)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    payment: state.payment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVendorPayment: id => { dispatch(paymentActions.getVendorPayment(id)) },
    getPayment: () => { dispatch(paymentActions.getAdminPayment) },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
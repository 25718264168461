import React from "react";
import { FormGroup, Input, Form } from "reactstrap";
import "./style.css";

const SearchField = (props) => {
  return (
    <FormGroup className="mb-0">
      <Input
        type="text"
        className="searchButton"
        name="search"
        id="searchbar"
        placeholder="Search"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </FormGroup>
  );
};

export default SearchField;

import React from "react";

import { icons } from "../CustomizedActivity";
import { formatDate } from "../../../../utilities";

const Normal = (props) => {
  const { type, text, createdAt, children } = props ?? {};
  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type.split("-")?.[0]]?.icon &&
          icons?.[type.split("-")?.[0]]?.icon}
        <div className="detail-wrapper">
          <div className="detail" dangerouslySetInnerHTML={{ __html: text }} />
          <div className="date">{formatDate(createdAt)}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Normal;

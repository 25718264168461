import React, { useState } from "react";
import { Collapse } from "@material-ui/core";

import FilePreview from "../../../components/UI/FilePreview";
import StnImage from "../../../components/UI/Image";
import { profilePlaceholder } from "../../../utilities";

const DeliveryData = (props) => {
  const { orderDetails } = props;
  const [collapse, setCollapse] = useState(false);

  const client = orderDetails?.user;
  const vendor = orderDetails?.owner;
  const orderDelivery = orderDetails?.orderDelivery ?? [];

  return (
    <div className="py-3">
      <span className="cursor-pointer" onClick={() => setCollapse(!collapse)}>
        See details
      </span>
      <Collapse in={collapse} unmountOnExit>
        {orderDelivery?.map((delivery, i) => {
          const isVendor = delivery?.forClient;
          const isClient = delivery?.forVendor;
          return (
            <div key={i} className="mt-4">
              <div className="py-3 px-3 text-xl font-semibold">
                {isVendor ? "Customer's " : isClient ? "Vendor's " : ""}
                pictures of delivered product
              </div>
              <div className="border-t-2 border-gray-300 py-3 px-3">
                <div style={{ gap: 12 }} className="d-flex align-items-start">
                  <StnImage
                    className="rounded-circle"
                    src={
                      isVendor
                        ? client?.profilePic
                        : isClient
                        ? vendor?.profilePic
                        : ""
                    }
                    placeholder={profilePlaceholder}
                    width={40}
                    height={40}
                    alt="Sender Profile"
                  />
                  <div>
                    <div className="text-lg font-semibold">
                      {isVendor
                        ? `${client?.firstName} ${client?.lastName}'s `
                        : isClient
                        ? `${vendor?.firstName} ${vendor?.lastName}'s `
                        : ""}
                      message
                    </div>
                    <div
                      className="font-halvatica"
                      dangerouslySetInnerHTML={{ __html: delivery.message }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  ATTACHMENTS
                  <div style={{ gap: 12 }} className="d-flex flex-wrap mt-2">
                    {delivery?.attachments?.map((attachment, index) => (
                      <FilePreview key={index} file={attachment} />
                    ))}
                  </div>
                </div>
                {delivery?.video?.url && (
                  <div className="mt-4">
                    VIDEO
                    <div
                      style={{ width: "15rem" }}
                      className="d-flex flex-wrap mt-2"
                    >
                      <div
                        style={{ height: "11rem" }}
                        className="d-flex justify-content-center align-items-center border rounded-lg overflow-hidden cursor-pointer"
                      >
                        <video
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          src={delivery?.video?.url}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export default DeliveryData;

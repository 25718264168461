/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { connect } from "react-redux";
import * as authActions from "./../../store/actions/authActions";
import { resetArticle } from "../../store/actions/articleActions";
import { resetCategory } from "../../store/actions/categoryActions";
import { resetCoupon } from "../../store/actions/couponAction";
import { resetOrder } from "../../store/actions/orderActions";
import { resetPayment } from "../../store/actions/paymentActions";
import { resetProduct } from "../../store/actions/productActions";
import { resetSetting } from "../../store/actions/settingActions";
import { resetUser } from "../../store/actions/userActions";

class Logout extends React.Component {
  componentDidMount = () => {
    const {
      resetArticle,
      resetCategory,
      resetCoupon,
      resetOrder,
      resetPayment,
      resetProduct,
      resetSetting,
      resetUser,
      history,
      logout,
    } = this.props;

    resetArticle();
    resetCategory();
    resetCoupon();
    resetOrder();
    resetPayment();
    resetProduct();
    resetSetting();
    resetUser();
    logout();
    history.push("/auth/login");
  };

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authActions.logout()),
    resetArticle: () => dispatch(resetArticle()),
    resetCategory: () => dispatch(resetCategory()),
    resetCoupon: () => dispatch(resetCoupon()),
    resetOrder: () => dispatch(resetOrder()),
    resetPayment: () => dispatch(resetPayment()),
    resetProduct: () => dispatch(resetProduct()),
    resetSetting: () => dispatch(resetSetting()),
    resetUser: () => dispatch(resetUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

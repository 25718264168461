import * as types from "../types/payment";

const initialState = {
  status: {
    isLoading: false,
    isLoaded: true,
  },
  payment: [],
  refund: [],
  refunds: [],
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VENDORE_REFUND:
    case types.VENDORE_REFUND:
    case types.GET_ADMIN_PAYMENT:
    case types.GET_VENDOR_PAYMENT:
      return { ...state, status: { isLoading: true, isLoaded: false } };
    case types.GET_ADMIN_PAYMENT_SUCCESS:
    case types.GET_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        status: { isLoaded: true, isLoading: false },
        payment: action.payload,
      };
    case types.GET_VENDORE_REFUND_FAILURE:
    case types.VENDORE_REFUND_FAILURE:
    case types.GET_ADMIN_PAYMENT_FAILURE:
    case types.GET_VENDOR_PAYMENT_FAILURE:
      return {
        ...state,
        status: {
          isLoaded: true,
          isLoading: false,
          loadingError: action.payload,
        },
      };
    case types.GET_VENDORE_REFUND_SUCCESS:
    case types.VENDORE_REFUND_SUCCESS:
      return {
        ...state,
        status: { isLoaded: true, isLoading: false },
        refund: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        status: { isLoaded: true, isLoading: false },
        payment: [],
        refund: [],
      };
    case types.GET_REFUND_SUCCESS: {
      return {
        ...state,
        refunds: action.payload,
      };
    }
    case types.RESET_PAYMENT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default payments;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const InvoiceDialog = ({ showModel, onClose, dataHtml }) => {
  const [modalIsOpen, setIsOpen] = useState(showModel);

  useEffect(() => {
    setIsOpen(showModel);
  }, [showModel]);

  function closeModal() {
    setIsOpen(false);
    onClose();
  }
  return (
    <Modal
      size="xl"
      dialogClassName="invoice-modal"
      show={modalIsOpen}
      onHide={closeModal}
    >
      <div className="d-flex justify-content-between my-2 mx-3 pt-2">
        <div className="">
          <button
            onClick={() => {
              const id = "receipt";
              const iframe = document.frames
                ? document.frames[id]
                : document.getElementById(id);
              const iframeWindow = iframe.contentWindow || iframe;

              iframe.focus();
              iframeWindow.print();
            }}
            className="text-xl border text-blue-700 rounded-md px-3 bg-gray-100 my-1 hover:bg-gray-200  shadow-lg"
          >
            Download
          </button>
        </div>
        <div>
          <button
            onClick={closeModal}
            className="text-md font-bold text-red-700 rounded-md px-2.5 bg-gray-300 my-1 hover:bg-gray-400  shadow-lg"
          >
            X
          </button>
        </div>
      </div>
      <div className="h-full ">
        <iframe
          id="receipt"
          srcDoc={dataHtml}
          style={{
            // position: 'absolute',
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            display: "block",
            minHeight: "88.5vh",
          }}
        />
      </div>
    </Modal>
  );
};

export default InvoiceDialog;
